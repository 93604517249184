//OwnableModel
module.exports = {
	isOwned: function() {

		if (this.has('level') && this.get('level') == 'owner') {
			return true;

		} else return false;

	},
	isEditable: function() {
		if (this.has('level') && (_.contains(['owner','writer'], this.get('level')))) {
			return true;

		} else return false;
	},
	isMember: function() {
		return this.has('level');
	},
	getUserRoleDescription: function() {
		var desc = {};

		var role = this.get('level');

		switch (role) {
			case 'owner':
				desc.title = "Owner";
				desc.text = "You have full permissions.";
				break;
			case 'writer':
				desc.title = "Contributor";
				desc.text = "You can upload files and edit metadata."
				break;
			case 'viewer':
				desc.title = "Viewer";
				desc.text = "You can view files and metadata."
				break;
		}

		return desc;
	},
};