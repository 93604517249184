//FileAPI
module.exports = {
	name: function(id) {
		return Mustache.render('/api/file/{{id}}/name', {
			id: id
		});
	},
	thumbnail: function(id) {
		return Mustache.render("/api/file/{{id}}/thumbnail?r={{rand}}", {
			id: id,
			rand: new Date().getTime()
		});
	},
	clone: function(id, shouldNotify) {
		var notify = false;
		if (shouldNotify) {
			notify = true;
		}
		return Mustache.render("/api/file/{{id}}/clone?notify={{notify}}", {
			id: id,
			notify: notify
		});
	},
	convertToNotebook: function(id) {
		return Mustache.render("/api/file/{{id}}/convert/notebook", {
			id: id
		});
	},
	moveFolder: function(id) {
		return Mustache.render("/api/folder/{{id}}/move", {
			id: id
		});
	}
}
