
var Events = require('events/projects/ProjectsEvents');

var CampaignTitleView = require('views/campaigns/CampaignTitleView');

var Campaign = require('models/Campaign');

var tmplCampaignView = require('campaigns/CampaignView.tmpl');

//CampaignView
module.exports = Backbone.Marionette.LayoutView.extend({
	template: tmplCampaignView,
	className: 'campaign-view',
	regions: {
		header: '.campaign-header',
		main: '.main'
	},
	onDestroy: function() {
		app.events.off(Events.getPageModel, this.getPageModel);
	},
	onShow: function() {
		var that = this;

		this.model = new Campaign({
			id:that.options.campaignId
		});

		that.model.fetch();
		$.when(that.model.fetch()).done(function(){
			that.header.show(new CampaignTitleView({
				model: that.model,
				selected: that.options.selected
			}));
		});
	},
	getPageModel: function() {
		return this.model;
	},
	initialize: function(options) {
		this.options = options;

		app.events.reply(Events.getPageModel, this.getPageModel, this);
	}
}, {
	isCurrent: function() {
		var currentView = app.m.getRegion('main').currentView;

		var CampaignView = require('views/campaigns/CampaignView');
		if (currentView instanceof CampaignView) {
			return true;

		} else return false;
	},
	updateModel: function(id, selected) {
		var currentView = app.m.getRegion('main').currentView;

		var model = new Campaign({
			id: id
		});

		model.fetch();
		$.when(model.fetch()).done(function(){
			currentView.header.show(new CampaignTitleView({
				model: model,
				selected: selected
			}));
		});
	}

}); //CampaignView