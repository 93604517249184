module.exports = _.extend(app, {
	defaults: {
		folderName: "New Folder"
	},
	selected: {
		group: app.selected.group,
		project: app.selected.project,
		metadata: app.selected.metadata,
		folder: {},
		file: {
			id: null
		}
	},
	widgets: {
		upload: {
			url: "/project/{{project}}/folder/{{folder}}/upload",
			getUrl: function() {
				return Mustache.to_html(this.url, {
					project: app.selected.project.id,
					folder: app.selected.folder.id
				});
			}

		},
		files: {
			table: null,
			initUrl: '/project/{{project}}/files.json{{#folder}}?folder={{folder}}{{/folder}}',
			getInitUrl: function(folderId) {
				return Mustache.to_html(this.initUrl, {
					project: app.selected.project.id,
					folder: folderId
				});
			},
			url: '{{path}}/project/{{project}}/folder/{{folder}}.table',
			getUrl: function() {		
				if (app.selected.folder) {
					return Mustache.to_html(this.url, {
						path: app.path,
						project: app.selected.project.id,
						folder: app.selected.folder.id
					});

				} else return Mustache.to_html("/project/{{id}}/files.json", {
					id: app.selected.project.id
				});
			},
			trash: {
				table: null,
				url: '{{path}}/project/{{project}}/files/deleted.table',
				getUrl: function() {
					return Mustache.to_html(this.url, {
						path: app.path,
						project: app.selected.project.id
					});
				}
			}
		},
		file: {
			url: '/files/{{id}}/{{fileName}}',
			getUrl: function(id, fileName) {
				return Mustache.to_html(this.url, {
					id: id,
					fileName: fileName
				});
			}
		}
	}
});