var Routing = require('routers/Routing');

//UserRouter
module.exports = Backbone.Router.extend({
	routes: {
		'u/:name': 'viewProfile',
		'u/:name/edit': 'editProfile',

		'u/:name/edit/address/': 'editAddress',
		'u/:name/edit/address/:id': 'editAddress',

		'u/:name/edit/position/': 'editPosition',
		'u/:name/edit/position/:id': 'editPosition',

		'u/:name/edit/picture': 'editPicture',
		'u/:name/edit/background': 'editBackground',

		'join': 'createUser',
		'preview': 'preview',
		'welcome': 'welcome',
		'messages': 'messages',
		'console': 'console'
	},
	initialize: function() {
		this.bind("all", this.change);
	},
	change: function() {
		$('.qtip').qtip('hide');
	},
	viewProfile: function(name) {

		require.ensure([], function() {
			var ProfileView = require('views/user/ProfileView');
			var User = require('models/User');

			var model = new User({
				profile: true,
				name: name
			});
			var view = new ProfileView({
				model: model
			});

			model.on("sync", function() {
				$(document).scrollTop(0);
				app.m.getRegion('main').show(view);
			});

			model.fetch();

		}); //require.ensure
	},
	editProfile: function(name) {
		log.debug('editProfile: %s', name);

		require.ensure([], function() {
			require("bootstrap/bootstrap.min.css");
			require("bootstrap/bootstrap.min.js");
			require("bootstrap-validator/validator.min.js");
			require("parsley/parsley.min.js");
			require('ParsleyValidators').init();

			var EditProfileView = require('views/user/EditProfileView');
			var ProfileFormView = require('views/user/ProfileFormView');

			var User = require('models/User');

			var model = new User({name: name});
			var view = new EditProfileView({
				model: model
			});

			model.on("sync", function() {
				app.m.getRegion('main').show(view);
				view.getRegion('main').show(new ProfileFormView({
					model:model
				}));
			});

			model.fetch();
		}); //require.ensure
	},
	editAddress: function(name, id) {
		log.debug('editAddress: %s %s', name, id);

		require.ensure([], function() {
			require("bootstrap/bootstrap.min.css");
			require("bootstrap/bootstrap.min.js");
			require("bootstrap-validator/validator.min.js");
			require("parsley/parsley.min.js");
			require('ParsleyValidators').init();

			var EditProfileView = require('views/user/EditProfileView');
			var ProfileAddressFormView = require('views/user/ProfileAddressFormView');

			var User = require('models/User');
			var Address = require('models/Address');

			var model = new User({name: name});
			var view = new EditProfileView({
				model: model
			});

			var address = new Address({
				id: id,
				username: name
			});

			//if existing address
			if (id) {
				$.when(model.fetch(), address.fetch()).done(function(){
					app.m.getRegion('main').show(view);

					view.main.show(new ProfileAddressFormView({
						model:address
					}));
				});
			} else {
				$.when(model.fetch()).done(function(){
					app.m.getRegion('main').show(view);

					view.main.show(new ProfileAddressFormView({
						model:address
					}));
				});
			}
		}); //require.ensure
	},
	editPosition: function(name, id) {
		log.debug('editPosition: %s %s', name, id);

		require.ensure([], function() {
			require("bootstrap/bootstrap.min.css");
			require("bootstrap/bootstrap.min.js");
			require("bootstrap-validator/validator.min.js");
			require("parsley/parsley.min.js");
			require('ParsleyValidators').init();

			var EditProfileView = require('views/user/EditProfileView');
			var ProfilePositionFormView = require('views/user/ProfilePositionFormView');

			var User = require('models/User');
			var Position = require('models/Position');

			var model = new User({name: name});
			var view = new EditProfileView({
				model: model
			});

			var position = new Position({
				id: id,
				username: name
			});

			//if existing position
			if (id) {
				$.when(model.fetch(), position.fetch()).done(function(){
					app.m.getRegion('main').show(view);

					view.main.show(new ProfilePositionFormView({
						model:position
					}));
				});
			} else {
				$.when(model.fetch()).done(function(){
					app.m.getRegion('main').show(view);

					view.main.show(new ProfilePositionFormView({
						model:position
					}));
				});
			}
		}); //require.ensure
	},
	editPicture: function(name) {
		log.debug('editPicture: %s', name);

		require.ensure([], function() {
			require('cropper/cropper.min');
			var UserProfilePicView = require('views/user/UserProfilePicView');

			var User = require('models/User');

			var model = new User({name: name});
			var view = new UserProfilePicView({
				model: model
			});

			$.when(model.fetch()).done(function(){
				app.m.getRegion('main').show(view);
			});

		}); //require.ensure
	},
	editBackground: function(name) {
		log.debug('editBackground: %s', name);

		require.ensure([], function() {
			require('cropper/cropper.min');
			var UserBackgroundPicView = require('views/user/UserBackgroundPicView');

			var User = require('models/User');

			var model = new User({name: name});
			var view = new UserBackgroundPicView({
				model: model
			});

			$.when(model.fetch()).done(function(){
				app.m.getRegion('main').show(view);
			});

		}); //require.ensure
	},
	createUser: function(id) {
		log.debug('show CreateUserView');

		require.ensure([], function() {
			require("bootstrap/bootstrap.min.css");
			var CreateUserView = require('views/user/CreateUserView');

			app.m.getRegion('main').show(new CreateUserView());

		}); //require.ensure
	},
	preview: function() {
		require.ensure([], function() {
			require("bootstrap/bootstrap.min.css");

			var PreviewCodeView = require('views/login/PreviewCodeView');
			app.m.getRegion('main').show(new PreviewCodeView());

		}); //require.ensure
	},
	welcome: function() {
		log.debug('show Welcome');

		require.ensure([], function() {
			require("bootstrap/bootstrap.min.css");
			require('cropper/cropper.min');

			var WelcomeView = require('views/user/WelcomeView');

			var params = Routing.getQueryParams();

			app.m.getRegion('main').show(new WelcomeView({
				type: params.type
			}));

		}); //require.ensure
	},
	messages: function() {

		require.ensure([], function() {
			var MessagesView = require('views/messages/MessagesView');

			app.m.getRegion('main').show(new MessagesView());

		}); 
	},
	console: function() {
		if (!app.user.isAdmin) {
			window.location = 'error/401';
		} 
	},
}); //router