import * as BluePromise from 'bluebird';

const Routing = require('routers/Routing');

const Projects = require('models/Projects');

const FileAPI = require('api/FileAPI');

// FileRouter
module.exports = Backbone.Router.extend({
  routes: {
    'file/:id/:name': 'editFile',
    'notebook-gallery': 'viewGallery',
    models: 'viewModels',
    dask: 'viewDaskDashboard',
    workshop: 'viewWorkshop'
  },
  editFile(id, name) {
    const params = Routing.getQueryParams();
    log.debug('editFile params: %o', params);

    // fetch name from server to avoid URL-encoding issues w/Backbone router
    const namePromise = $.when(
      $.ajax({
        url: FileAPI.name(id),
        method: 'GET'
      })
    );

    BluePromise.all([
      Projects.fromFile(id),
      namePromise,
      import(
        /* webpackChunkName: "FileEditorView" */ 'views/editor/FileEditorView'
      )
    ]).spread(function(project, fileName, { default: FileEditorView }) {
      app.m.getRegion('main').show(
        new FileEditorView({
          fileId: id,
          name: fileName,
          newFile: params.new || false,
          editor: params.editor
        })
      );
    });
  },
  viewDaskDashboard() {
    require.ensure([], function() {
      const DaskDashboardView = require('views/dask/DaskDashboardView');

      app.m.getRegion('main').show(new DaskDashboardView());
    }); // require.ensure
  },
  viewGallery() {
    require.ensure([], function() {
      const NotebookGalleryView = require('views/notebook-gallery/NotebookGalleryView');

      app.m.getRegion('main').show(new NotebookGalleryView());
    }); // require.ensure
  },
  viewWorkshop() {
    require.ensure([], function() {
      const WorkshopView = require('views/workshop/WorkshopView');

      app.m.getRegion('main').show(new WorkshopView());
    }); // require.ensure
  },
  viewModels() {
    require.ensure([], function() {
      const ModelLibraryView = require('views/model-library/ModelLibraryView');

      app.m.getRegion('dialog').show(new ModelLibraryView());
    }); // require.ensure
  }
}); // FileRouter
