const UserRoleView = require('mixins/UserRoleView');
const ContextMenu = require('mixins/ContextMenu');
const DataCoordinators = require('util/DataCoordinators');

const PageEvents = require('events/PageEvents');

const CampaignEvents = require('events/campaigns/CampaignEvents');

const Routing = require('routers/Routing');
const routes = require('routes/CampaignRoutes');

const tmplCampaignHeader = require('campaigns/CampaignHeader.tmpl');

// CampaignTitleView
module.exports = Backbone.Marionette.ItemView.extend(
  _.extend({}, UserRoleView, {
    template: tmplCampaignHeader,
    className: 'rw-header',
    ui: {
      usrRoleLevel: 'div.role-level',
      confirmDelete: 'div.dlgCampaignDelete'
    },
    events: {
      'click a.about': 'viewAbout',
      'click a.projects': 'viewProjects',
      'click li.settings a': 'viewSettings',
      'click a.archives': 'viewArchives',
      'click a.contacts': 'viewContacts',
      'click a.metadata': 'viewMetadata',
      'click a.reports': 'viewReports',
      'click a.notices': 'viewNotices',
      'click li.more-actions': 'viewMoreActions',
      'click a.help': 'startTutorial'
    },
    viewAbout(event) {
      if (Routing.modified(event)) {
        return;
      }

      app.router.navigate(
        routes.campaignAbout(this.model.id, this.model.get('slug')),
        true
      );
    },
    viewProjects(event) {
      if (Routing.modified(event)) {
        return;
      }

      app.router.navigate(
        routes.campaign(this.model.id, this.model.get('slug')),
        true
      );
    },
    viewSettings(event) {
      if (Routing.modified(event)) {
        return;
      }

      app.router.navigate(
        routes.campaignSettings(this.model.id, this.model.get('slug')),
        true
      );
    },
    viewArchives(event) {
      if (Routing.modified(event)) {
        return;
      }

      app.router.navigate(
        routes.campaignArchives(this.model.id, this.model.get('slug')),
        true
      );
    },
    viewContacts(event) {
      if (Routing.modified(event)) {
        return;
      }

      app.router.navigate(
        routes.campaignContacts(this.model.id, this.model.get('slug')),
        true
      );
    },
    viewMetadata(event) {
      if (Routing.modified(event)) {
        return;
      }

      app.router.navigate(
        routes.campaignMetadata(this.model.id, this.model.get('slug')),
        true
      );
    },
    viewReports(event) {
      event.preventDefault();

      const groupId = this.model.get('id');
      const url = Mustache.render(
        '/report-submissions/org/{{groupId}}/projects',
        {
          groupId
        }
      );

      window.open(url);
    },
    viewNotices(event) {
      if (Routing.modified(event)) {
        return;
      }

      app.router.navigate(
        routes.campaignNotices(this.model.id, this.model.get('slug')),
        true
      );
    },
    viewMoreActions(event) {
      event.preventDefault();

      // create menu dynamically each time
      // to ensure menu is still bound despite subnav views changing
      $.contextMenu('destroy', 'li.more-actions i');
      this.bindMoreActions();

      // show menu
      $('li.more-actions i').contextMenu();
    },
    bindMoreActions() {
      const that = this;

      const options = {
        selector: 'li.more-actions i',
        trigger: 'none',
        items: {
          metadata: {
            name: 'View campaign metadata',
            materialIcon: 'dashboard',
            callback: _.bind(that.viewCampaignMetadata, that)
          }
        }
      }; // options

      if (that.model.isOwned() && DataCoordinators.isCoordinator()) {
        options.items.embargo = {
          name: 'Embargo settings',
          materialIcon: 'date_range',
          callback: _.bind(that.viewEmbargoSettings, that)
        };
      }

      if (DataCoordinators.isArchiver()) {
        options.items.archive = {
          name: 'Archive Settings',
          materialIcon: 'cloud_queue',
          callback: _.bind(that.archiveSettings, that)
        };
      }

      if (that.model.isOwned()) {
        options.items.sep1 = '---';
        options.items.delete = {
          name: 'Delete campaign',
          materialIcon: 'delete',
          callback: _.bind(that.deleteCampaign, that)
        };
      }

      options.determinePosition = function($menu) {
        $menu
          .css('display', 'block')
          .position({ my: 'left top', at: 'center bottom', of: this })
          .css('display', 'none');
      };

      ContextMenu.bindMenu(options);
    },
    startTutorial(event) {
      event.preventDefault();

      const that = this;

      require.ensure([], function() {
        const UserGuide = require('util/UserGuide');
        let tutorial;

        switch (that.selected) {
          case 'projects':
            tutorial = require('util/tutorials/CampaignProjectsTutorial');
            break;
          case 'settings':
            tutorial = require('util/tutorials/CampaignSettingsTutorial');
            break;
          default:
            log.warn('unknown tutorial tab: %s', that.selected);
            return;
        }

        if (tutorial) {
          UserGuide.loadTutorial(tutorial);
        }
      });
    },
    showTutorialIndicator() {
      this.$el.find('a.help').show();
    },
    hideTutorialIndicator() {
      this.$el.find('a.help').hide();
    },
    hasTutorial(selected) {
      const tutorials = ['projects', 'settings'];

      if (selected === 'projects') {
        if (this.model.get('projectCount') === 0) {
          log.debug('no projects, tutorial not available');

          return false;
        }
      }

      return _.contains(tutorials, selected);
    },
    viewEmbargoSettings() {
      app.router.navigate(routes.campaignEmbargoes(this.model.id), true);
    },
    archiveSettings() {
      app.router.navigate(
        routes.campaignArchiveSettings(this.model.id, this.model.get('slug')),
        true
      );
    },
    deleteCampaign() {
      const that = this;

      this.ui.confirmDelete.dialog({
        resizable: false,
        modal: true,
        width: 450,
        buttons: {
          Delete() {
            const thisDialog = this;
            that.model.destroy({
              wait: true,
              success(model, response) {
                $(thisDialog).dialog('close');
                app.router.navigate(routes.campaigns(), true);
              }
            });
          },
          Cancel() {
            $(this).dialog('close');
          }
        }
      });
    },
    viewCampaignMetadata() {
      app.router.navigate(
        routes.campaignMetadata(this.model.id, this.model.get('slug')),
        true
      );
    },
    selectTab(clazz) {
      this.$el.find('ul.nav li a').removeClass('selected');
      this.$el.find(`ul.nav a.${clazz}`).addClass('selected');
    },
    setColor(settings) {
      const header = this.$el.find('.rw-header');
      header.removeClass(settings.oldColor);
      header.addClass(settings.color);
    },
    onDestroy() {
      app.events.off(PageEvents.changed, this.selectTab);
      app.events.off(CampaignEvents.setColor, this.setColor);
    },
    onShow() {
      if (this.selected) {
        this.selectTab(this.selected);

        if (this.hasTutorial(this.selected)) {
          this.showTutorialIndicator();
        } else this.hideTutorialIndicator();
      }

      if (this.model.isOwned()) {
        this.$el.find('li.settings').show();
      }

      if (!this.model.isMember()) {
        this.$el.find('a.projects').hide();
      }

      this.setUserRoleIndicator(this.model);

      app.events.trigger(PageEvents.setTitle, this.model.get('name'));

      const slug = Routing.slugify(this.model.get('name'));
      const campaignRoute = routes.campaign(this.model.id, slug);
      app.events.trigger(PageEvents.setLastParentView, {
        name: 'campaign',
        route: campaignRoute
      });

      app.events.on(PageEvents.changed, this.selectTab, this);
      app.events.on(CampaignEvents.setColor, this.setColor, this);
    },
    initialize(options) {
      this.model = options.model;
      this.selected = options.selected;
    }
  })
);
