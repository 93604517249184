// DataCoordinators
// In V2, these are controlled by `coordinator` and `archiver` roles.
module.exports = {
  users: [
    'acanino',
    'admin',
    'cturner',
    'idgill',
    'iwen',
    'kkhazmutdinova',
    'klassagne',
    'lshowalter',
    'srstsavage',
    'mwolfshorndl'
  ],
  archivers: [
    'acanino',
    'admin',
    'cturner',
    'idgill',
    'iwen',
    'kkhazmutdinova',
    'klassagne',
    'lshowalter',
    'srstsavage',
    'mwolfshorndl'
  ],
  get() {
    return this.users;
  },
  isCoordinator() {
    return _.contains(this.users, app.user.name);
  },
  isArchiver() {
    return _.contains(this.archivers, app.user.name);
  }
};
