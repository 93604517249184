//UserAPI
module.exports = {
	getProfile: function(name) {
		return Mustache.render('/api/user/{{name}}/profile', {
			name: name
		});
	},
	getPicture: function(id) {
		return Mustache.render('/user/{{id}}/pic', {
			id: id
		});
	},
	getOriginalBackground: function(id) {
		return Mustache.render('/api/user/{{id}}/background/original', {
			id: id
		});
	},
	cropBackground: function(id) {
		return Mustache.render('/api/user/{{id}}/background/crop', {
			id: id
		});
	},
	getEditProfile: function(name) {
		return Mustache.render('/api/user/{{name}}/profile/edit', {
			name: name
		});
	},
	getProfileAddress: function(name, id) {
		return Mustache.render('/api/user/{{name}}/address/{{id}}', {
			name: name,
			id: id
		});
	},
	getProfilePosition: function(name, id) {
		return Mustache.render('/api/user/{{name}}/position/{{id}}', {
			name: name,
			id: id
		});
	},
	getInfo: function(id) {
		return Mustache.render('/api/user/{{id}}/info', {
			id: id
		});
	},
	isNameValid: function() {
		return "/api/user/name/valid"
	},
	isEmailValid: function() {
		return "/api/user/email/valid"
	},
	sendFeedback: function() {
		return "/api/feedback"
	},
	resetPassword: function() {
		return "/login/changePassword"
	}
}