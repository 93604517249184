//MessageAPI
module.exports = {
	getMiniMessages: function() {
		return '/api/messages/mini';
	},
	getAll: function() {
		return '/api/messages/all';
	},
	getUnread: function() {
		return '/api/messages/unread';
	},
	markRead: function() {
		return '/api/messages/read';
	},
	action: function(action) {
		return Mustache.render("/api/messages/action/{{action}}", {
			action: action
		});
	}
}