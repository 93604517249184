//ProjectRoutes
module.exports = {
	createProject: function() {
		return 'projects/create';
	},
	projects: function(type) {
		var url = 'projects';

		if (!type) {
			return url;
		} else {
			url += "?type=" + type;
		}

		return url;
	},
	projectSettings: function(id) {
		return Mustache.render("project/{{id}}/settings", {
			id: id
		});
	},
	projectArchives: function(id) {
		return Mustache.render("project/{{id}}/archives", {
			id: id
		});
	},
    projectAssets: function(id) {
		return Mustache.render("project/{{id}}/assets", {
			id: id
		});
	},
	projectInvitations: function(id) {
		return Mustache.render("project/{{id}}/invitations", {
			id: id
		});
	},
	createCampaignProject: function(id, name) {
		return Mustache.render("campaign/{{id}}/{{name}}/project/create", {
			id: id,
			name: name
		});
	},
	createOrgProject: function(id, name) {
		return Mustache.render("org/{{id}}/{{name}}/project/create", {
			id: id,
			name: name
		});
	},
	campaignProjectSettings: function(id, name, projectId) {
		return Mustache.render("campaign/{{id}}/{{name}}/project/{{projectId}}/settings", {
			id: id,
			name: name,
			projectId: projectId
		});
	},
	orgProjectSettings: function(id, name, projectId) {
		return Mustache.render("org/{{id}}/{{name}}/project/{{projectId}}/settings", {
			id: id,
			name: name,
			projectId: projectId
		});
	},
	projectSettingsAddMembers: function(id) {
		return Mustache.render("project/{{id}}/settings/add-members", {
			id: id
		});
	},
	campaignProjectSettingsAddMembers: function(id, name, projectId) {
		return Mustache.render("campaign/{{id}}/{{name}}/project/{{projectId}}/settings/add-members", {
			id: id,
			name: name,
			projectId: projectId
		});
	},
	projectSettingsAddCampaigns: function(id) {
		return Mustache.render("project/{{id}}/settings/add-campaigns", {
			id: id
		});
	},
	campaignProjectSettingsAddCampaigns: function(id, name, projectId) {
		return Mustache.render("campaign/{{id}}/{{name}}/project/{{projectId}}/settings/add-campaigns", {
			id: id,
			name: name,
			projectId: projectId
		});
	},
	projectSettingsAddOrgs: function(id) {
		return Mustache.render("project/{{id}}/settings/add-organizations", {
			id: id
		});
	},
	campaignProjectSettingsAddOrgs: function(id, name, projectId) {
		return Mustache.render("campaign/{{id}}/{{name}}/project/{{projectId}}/settings/add-organizations", {
			id: id,
			name: name,
			projectId: projectId
		});
	},
	files: function(id) {
		return Mustache.render("project/{{id}}/files", {
			id: id
		});
	},
	folder: function() { 
		if (app.selected.folder) {
			return Mustache.render("project/{{id}}/folder/{{folderId}}/{{{name}}}", {
				id: app.selected.project.id,
				folderId: app.selected.folder.id,
				name: app.selected.folder.getEncodedName()
			});
		} else { //TODO: use files() route instead
			return Mustache.render("project/{{id}}/files", {
				id: app.selected.project.id
			});
		}
	},
	specificFolder: function(id, folderId) {
		return Mustache.render("project/{{id}}/folder/{{folderId}}", {
			id: id,
			folderId: folderId
		})
	},
	trash: function() {
		return Mustache.render("project/{{id}}/trash", {
			id: app.selected.project.id
		})
	},
	fileMetadata:function(id,fileId){
		return Mustache.render("project/{{id}}/files/{{fileId}}/metadata", {
			id: id,
			fileId:fileId
		})
	},
	folderMetadata:function(id,folderId){
		return Mustache.render("project/{{id}}/folders/{{folderId}}/metadata", {
			id:id,
			folderId:folderId
		})
	},
	metadata: function(id) {
		return Mustache.render("project/{{id}}/metadata", {
			id: id
		})
	},
	projectActivity: function(id) {
		return Mustache.render("project/{{id}}/activity", {
			id: id
		})
	},
	download: function(id) {
		return Mustache.render("/project/{{id}}/zip", {
			id: id
		})
	},
	archiveFolder: function(id, folderId) {
		return Mustache.render("/project/{{id}}/archive/folder/{{folderId}}", {
			id: id,
			folderId: folderId
		})
	},
	submitArchive: function(id) {
		return Mustache.render("/archive/{{id}}/submit", {
			id: id
		})
	},
	archiveRequest: function(projectId, id) {
		return Mustache.render("project/{{projectId}}/archive-request/{{id}}", {
			projectId: projectId,
			id: id
		})
	},
	addExternalArchive: function(projectId, folderId) {
		return Mustache.render("project/{{projectId}}/archives/add-external/{{folderId}}", {
			projectId: projectId,
			folderId: folderId
		})
	},
	externalArchive: function(projectId, archiveId) {
		return Mustache.render("project/{{projectId}}/archives/external/{{archiveId}}", {
			projectId: projectId,
			archiveId: archiveId
		})
	},
	projectReport: function(projectId) {
		return Mustache.render("/project/{{projectId}}/reports",
		{
			projectId:projectId

		})
	}
}