var Events = require('events/editor/EditorEvents');

//SocketIOListener
module.exports = {
	initialize: function() {
        var that = this;

        that.address = Mustache.render("{{{host}}}:{{port}}{{{path}}}", {
                    host: app.config.socketio.host,
                    port: app.config.socketio.port,
                    path: app.config.socketio.path
                });

        that.socket = io.connect(that.address, {query:'token='+jwt});

        that.socket.on('error', function() { console.error(arguments) });
        that.socket.on('message', function() { console.log(arguments) });

        that.socket.on('expired', function() {
            log.debug('token expired');

            delete that.socket;

            that.socket = that.updateToken();
        });

        var channel = 'user:' + app.user.id;
        that.join(channel);

        log.debug('SocketIO listening for %s on %s', channel, that.address);
	},
    join: function(channel) {
        var that = this;
        log.debug('joining channel %s', channel);

        //TODO: wait and retry if socket not initialized
        if (this.socket) {
            this.socket.emit("subscribe", { channel: channel });
            this.socket.on(channel, that.listen);
        }
    },
    leave: function(channel) {
        var that = this;
        log.debug('leaving channel %s', channel);

        if (this.socket) {
            this.socket.emit("unsubscribe", { channel: channel });
            this.socket.removeEventListener(channel, that.listen);
        }
    },
    listen: function(data) { 
        // log.debug('channel %s received: %s', channel, JSON.stringify(data));
        log.debug('received: %s', JSON.stringify(data));

        if (data.event){
            log.debug('triggering event %s', data.event);
            app.events.trigger(data.event, data);
        }
    },
    updateToken: function() {
        var that = this;

        $.ajax({
            url: '/api/io/token',
            success: function(token) {
                log.debug('new token: %s', token);
                jwt = token;
                return io.connect(that.address, {query:'token='+jwt});
            }
        });
    }
}