var OwnableModel = require('mixins/OwnableModel');

//Organization
module.exports = Backbone.Model.extend(_.extend({}, OwnableModel, {
	urlRoot: "/api/org",
	hasPicture: function() {
		if (this.has('color')) {
			return false;
		} else return true;
	}
}));