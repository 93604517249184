//ArchiveAPI
module.exports = {
	archive: function(id) {
		return Mustache.render("/api/archive/{{id}}", {
			id: id
		});
	},
	getFiles: function(id) {
		return Mustache.render("/api/archive/{{id}}/files", {
			id: id
		});
	},
	getFolders: function(id) {
		return Mustache.render('/api/archive/{{id}}/folders', {
			id: id
		});
	},
	getProjectFolders: function(id) {
		return Mustache.render('/api/project/{{id}}/archive/folders', {
			id: id
		});
	},
	moveFolder: function(id, folderId) {
		return Mustache.render('/api/archive/{{id}}/folder/{{folderId}}/move', {
			id: id,
			folderId: folderId
		});
	},
	getProjectArchives: function(id) {
		return Mustache.render('/api/project/{{id}}/archives', {
			id: id
		});
	},
	getCampaignArchives: function(id) {
		return Mustache.render('/api/campaign/{{id}}/archives', {
			id: id
		});
	},
	getOrgArchives: function(id) {
		return Mustache.render('/api/org/{{id}}/archives', {
			id: id
		});
	},
	generateDOI: function(id) {
		return Mustache.render('/api/archive/{{id}}/generate-doi', {
			id: id
		});	
	},
	generatePublicationAgreement: function(id) {
		return Mustache.render('/api/archive/{{id}}/generate-agreement', {
			id: id
		});	
	},
	submitArchive: function(id) {
		return Mustache.render('/api/archive/{{id}}/submit', {
			id: id
		});	
	},
	getMetadata: function(id, format) {
		return Mustache.render('/api/metadata/export/{{id}}/{{format}}', {
			id: id,
			format: format
		});
	},
	projectArchivePath: function(id) {
		return Mustache.render('/api/project/{{id}}/archive-path', {
			id: id
		});
	},
	archiveRequest: function(id) {
		return Mustache.render('/api/archive-request/{{id}}', {
			id: id
		});
	},
	claimArchiveRequest: function(id) {
		return Mustache.render('/api/archive-request/{{id}}/claim', {
			id: id
		});
	},
	getRequestFiles: function(id) {
		return Mustache.render('/api/archive-request/{{id}}/files', {
			id: id
		});
	},
	getRequestFile: function(id, path) {
		return Mustache.render('/api/archive-request/{{id}}/file/{{path}}', {
			id: id,
			path: path
		});
	},
	getRequestFolder: function(id, path) {
		return Mustache.render('/api/archive-request/{{id}}/folder/{{path}}', {
			id: id,
			path: path
		});
	},
	approveRequest: function(id) {
		return Mustache.render('/api/archive-request/{{id}}/approve', {
			id: id
		});
	},
	campaignArchiveAgreeement: function(id, folderId) {
		return Mustache.render('/api/campaign/{{id}}/folder/{{folderId}}/archive-path/agreement', {
			id: id,
			folderId: folderId
		});
	},
	setNCEIFields: function(id) {
		return Mustache.render('/api/archive-request/{{id}}/ncei-fields', {
			id: id
		});
	},
	dataoneCitation: function(id) {
		return Mustache.render('/api/archive/{{id}}/citation-dataone', {
			id: id
		});	
	},
	citation: function(id) {
		return Mustache.render('/api/archive/{{id}}/citation', {
			id: id
		});	
	},
	addExternal: function(id) {
		return Mustache.render('/api/archive/external/folder/{{id}}', {
			id: id
		});	
	},
	external: function(id) {
		return Mustache.render('/api/archive/external/{{id}}', {
			id: id
		});	
	}
}