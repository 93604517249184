var TableView = require('views/files/TableView');

var File = require('models/File');
var Files = require('models/Files');
var Models = require('models/Models');

var Events = require('events/search/SearchEvents');

var Routing = require('routers/Routing');
var routes = require('routes/ProjectRoutes');

var tmplFileResult = require('search/FileResult.tmpl');
var tmplContactResult = require('search/ContactResult.tmpl');

var tmpl = require('search/SearchResultsView.tmpl');

//SearchResultsView
module.exports = TableView.extend({
	template: tmpl,
	className: 'search-results',
	// el: '#fileList',
	// baseElement: '#fileList table',
	events: {},
	hasCheckbox: false,
	metadataIcon: '<img src="/public/images/glyphicons/glyphicons_090_table_16x16.png" class="metadata" alt="View metadata">',
	events: {
		'click a.file': 'navFile',
		'click a.project': 'navLink',
		'click a.folder': 'navLink',
		'click a.user': 'navLink'
		// 'mouseover span.project': 'viewProjectTooltip'
	},
	onDestroy: function() {
		$(document).off('click.searchblur', this.searchBlurEvent);

		Mousetrap.unbind(['up', 'down', 'enter']);
	},
	navFile: function(event) {
		app.events.trigger(Events.clearSearch);

		var href = $(event.currentTarget).attr('href');
		if (Routing.isEditableFile(href)) {
			this.navLink(event);
		}
	},
	navLink: function(event) {
		event.preventDefault();
		event.stopPropagation();

		var href = $(event.currentTarget).attr('href');
		app.router.navigate(href, true);

		this.destroy();
	},
	viewProjectTooltip: function(event) {
		var el = $(event.currentTarget);
		var id = el.attr('data-id');

		app.views.projectsList.showTooltip(event, el, id);
	},
	bindCheckboxes: function() {
		var table = $(this.$el.find('table'));

		//clicking on parent TD toggles checkbox
		table.find("input[type=checkbox]").parent('td').click(function(event) {
			var input = $(event.currentTarget).children('input[type=checkbox]:first-child');
			input.prop("checked", !input.prop("checked"));

			if (app.views.fileMove) {
				app.views.fileMove.updateSelectedCount();
			}
		});


		app.widgets.lastChecked = null;
		table.find('input[type=checkbox]').click(function(event) {

			if (app.views.fileMove) {
				app.views.fileMove.updateSelectedCount();
			}

			if (!app.widgets.lastChecked) {
				app.widgets.lastChecked = this;
				return;
			}

			//shift key multi-select
			if (event.shiftKey) {
				var start = $('input:checkbox').index(this);
				var end = $('input:checkbox').index(app.widgets.lastChecked);

				for (i = Math.min(start, end); i <= Math.max(start, end); i++) {
					$('input:checkbox')[i].checked = app.widgets.lastChecked.checked;
				}

				if (app.views.fileMove) {
					app.views.fileMove.updateSelectedCount();
				}
			}

			app.widgets.lastChecked = this;
		});
	}, //bindCheckboxes
	setData: function(data) {
		var that = this;
		that.collection.reset();
		that.table.fnPageChange(0);

		$.each(data, function(key, record) {

			var params = {
				id: record.id,
				uuid: record.uuid,
				userId: record["user.id"],
				fileName: record.fileName,
				size: record.size,
				userName: record["user.fullName"],
				lastModified: record.created,
				lastModifiedRaw: record.created,
				metadataId: record["metaId"],
				projectId: record["project.id"],
				folderId: record["folder.id"],
				folderName: record["folder.name"]
			};

			var file = new File(params);
			that.collection.add(file);
		});

		that.table.fnReloadAjax();

		if (data && data.length > 0) {
			// this.show();
		}
	},
	shorten: function(str) {
		if (str.length > 20) {
			str = str.substring(0, 20) + "...";
		}

		return str;
	},
	getPageSize: function() {
		var availableHeight = ($(window).height() - (300));
		var realEstate = availableHeight / 42;
		return Math.floor(realEstate);
	},
	bindTable: function() {
		var that = this;

		var perPage = that.getPageSize();

		this.table = this.$el.find('table').DataTable( {
			"sDom": '<"toolbar">frtip',
			"bJQueryUI": true,
			"bLengthChange": false,
			"bDeferRender": true,
			"bProcessing":true,
			"bDestroy": true,
			"bSort": false,
			"bFilter": false,
			"bInfo": false, /* number of results */
			"sPaginationType": "full_numbers",
			"iDisplayLength": perPage,
			"oLanguage": {
				"sEmptyTable": "No files.",
				"sInfo": "Showing _START_ to _END_ of _TOTAL_ files",
				"sSearch": "Filter:"
			},
			"aaSorting": [],
			//"aaSorting": [[ 7, "desc" ]],
			"aoColumnDefs": [
				/*{ "sWidth": "30px","bSortable": false, "aTargets": [ 0 ] },*/
				{ "sWidth": "30px","bSortable": false, "aTargets": [ 0 ] },
				{ "sWidth": "100px", "aTargets": [ 4 ] },
				{ "sWidth": "200px", "aTargets": [ 5 ] },
				{ "sWidth": "120px", "aTargets": [ 6 ] },
				{ "bVisible": false, "aTargets": [ 1,2,7 ] },
				{ "iDataSort":7, "aTargets": [ 6 ] }
			],
			"aoColumns": [
				/* { "mDataProp": null }, */
				{ "sTitle": " ",   "mDataProp": null },
				{ "sTitle": "uuid",   "mDataProp": "uuid" },
				{ "sTitle": "User",  "mDataProp": "userId" },
				{ "sTitle": "Name",  "mDataProp": "fileName" },
				{ "sTitle": "Size",  "mDataProp": "size" },
				{ "sTitle": "Uploaded By",  "mDataProp": "userName" },
				{ "sTitle": "Updated",  "mDataProp": "lastModified" },
				{ "sTitle": "Updated (milliseconds)",  "mDataProp": "lastModifiedRaw" }
			], 
			sAjaxSource: "",
			sAjaxDataProp: "",
			fnServerData: function(sSource, aoData, fnCallback) {
				fnCallback(that.collection.toJSON());
			},

			"fnDrawCallback": function() {
				console.log('drawCallback');

				that.bindCheckboxes();
				jQuery(".timeago").timeago();
				that.bindViewUser();

				if (!$('.searchResults').hasClass('composite')) {
					var text = $('#header .searchBox input.search').val();
					var array = text.split(" ");

					_.each(array, function(term) {
						that.$el.find('a.file').highlight(term);
					});

					if (array.length == 0) {
						that.$el.removeHighlight();
					}
				}

				that.checkPaginationVisibility(this.fnSettings());
			},
			"fnRowCallback": function(nRow, file, iDisplayIndex, iDisplayIndexFull) {
				var projects = app.models.get(Models.PROJECTS);
				var project = projects.get(file.projectId);

				$(nRow).attr("id", file.id);
				$(nRow).addClass('file');

				if (file.fileName) {
					file.url = app.widgets.file.getUrl(file.id, file.fileName);
					// file.project = project.toJSON();
					// file.project.name = that.shorten(file.project.name);
					// file.project.url = routes.specificFolder(project.id, file.folderId);
					// file.folderName = that.shorten(file.folderName);

					var html = tmplFileResult(file);
					$('td:eq(1)', nRow).html(html);
				}

				if (file.userName) {
					file.group = app.selected.group;

					var html = that.templates.filesUser(file);
					$('td:eq(3)', nRow).html(html);
				}

				$('td:eq(0)', nRow).html(that.metadataIcon).addClass('metadata-status');

				if (file.metadataId) {
					$('td:eq(0)', nRow).addClass('hasMetadata');
				} else {
					$('td:eq(0)', nRow).removeClass('hasMetadata');
				}


				return nRow;

			} //fnRowCallback
		}); //dataTable  
		// that.bindFileMetadata();

		this.setData(this.results.files);

	}, //bindTable
	setData: function(data) {
		var that = this;
		that.collection.reset();
		// that.table.fnPageChange(0);

		$.each(data, function(key, record) {

			var params = {
				id: record.id,
				uuid: record.uuid,
				userId: record["user.id"],
				fileName: record.fileName,
				size: record.size,
				userName: record["user.fullName"],
				lastModified: record.created,
				lastModifiedRaw: record.created,
				metadataId: record["metaId"],
				projectId: record["project.id"],
				folderId: record["folder.id"],
				folderName: record["folder.name"]
			};

			var file = new File(params);
			that.collection.add(file);
		});

		// that.table.fnReloadAjax();
		log.debug('that.table.ajax: %o', that.table.ajax);
		that.table.ajax.reload();

		if (data && data.length > 0) {
			// this.show();
		}
	},
	bindBlur: function() {
		var that = this;

		this.searchBlurEvent = _.bind(this.searchBlur, this);

		$(document).on('click.searchblur', this.searchBlurEvent);
	},
	searchBlur: function(event) {
		if(!$(event.target).closest('.search-results').length 
			&& !$(event.target).closest('input.search').length) {

			app.events.trigger(Events.clearSearch);
			this.destroy();
		}
	},
	bindShortcuts: function() {
		var that = this;

		that.selectedIndex = 0;
		Mousetrap.bind('down', function(e) {
			e.preventDefault();

			var items = that.$el.find('ul li');

			if (that.selected) {
				that.selected.removeClass('selected');
			}

		    if ( !that.selected || that.selected.is(items[items.length-1])) {
		    	// log.debug('hit end of search results')

				that.selected = items.eq(0);
				that.selectedIndex = 0;

			} else {
				that.selectedIndex++;
				that.selected = items.eq(that.selectedIndex);
			}

			that.selected.addClass('selected');
		});

		Mousetrap.bind('up', function() {
		    var items = that.$el.find('ul li');
			if (that.selected) {
				that.selected.removeClass('selected');
			}

			if ( !that.selected || that.selected.is(items[0]) ) {

				that.selectedIndex = items.length-1;
				that.selected = items.eq(that.selectedIndex);
				
			} else {
				that.selectedIndex--;
				that.selected = items.eq(that.selectedIndex);
			}

			that.selected.addClass('selected');
		});

		Mousetrap.bind('enter', function() {
			if (that.selected) {
				var fileAnchor = that.selected.find('a.file');
				var userAnchor = that.selected.find('a.user');

				if (fileAnchor.length) {
					var href = fileAnchor.attr('href');

					if(Routing.isEditableFile(href)) {
						fileAnchor.click();

					} else {
						//not permitted by browsers to launch file,
						//browse to folder instead
						that.selected.find('a.folder').click();
					}
				} else if (userAnchor.length) {
					userAnchor.click();
				}
			}
		});
	},
	setHeaderText: function(options) {
		var count = options.count;

		var header = this.$el.find('h2' + options.clazz);

		if (count > 0) {
			header.find('span.count').html(count);
		}

		var itemText = '';
		if (count > 1) {
			itemText = options.plural;

		} else if (count == 1) {
			itemText = options.singular;

		} else {
			header.hide();
		}

		header.find('span.item').html(itemText);
	},	
	onShow: function() {
		var that = this;

		log.debug('results: %o', this.results);

		/* set contact results */

		var contactCount = this.results.contacts.total;

		this.setHeaderText({
			clazz: '.contacts',
			plural: 'people:',
			singular: 'person:',
			count: contactCount
		})

		_.each(this.results.contacts.items, function(contact) {
			var html = tmplContactResult(contact);
			that.$el.find('ul.contacts').append(html);
		});


		/* file results below */
		var fileCount = 0;
		if (this.results.files) {
			that.$el.find('h2.files').show();

			fileCount = this.results.files.total;

			this.setHeaderText({
				clazz: '.files',
				plural: 'files:',
				singular: 'file:',
				count: fileCount
			})

			_.each(this.results.files.items, function(file) {
					if (file.fileName) {
						file.url = Routing.getFileURL(file.id, file.fileName);

						var html = tmplFileResult(file);
						that.$el.find('ul.files').append(html);
					}
			});
		}



		if (contactCount == 0 && fileCount == 0) {
			var firstHeader = this.$el.find('h2.contacts');
			firstHeader.find('span.item').html('no results.');
			firstHeader.show();
		}

		this.bindBlur();
		this.bindShortcuts();
	},
	initialize: function(options) {
		this.results = options.results;

		// console.log('SearchResultsView init');
		this.collection = new Files();

		// this.bindTable();
		// this.$el.hide();
	}
});//FileListView