//ErrorRouter
module.exports = Backbone.Router.extend({
	routes: {
		'error/:code': 'error'
	},
	error: function(code) {
		log.debug('ErrorRouter: %s', code);
		
		require.ensure([], function() {
			var ErrorView = require('views/error/ErrorView');
			app.m.getRegion('main').show(new ErrorView({
				code:code
			}));
		});
	}
}); //router