var UserTooltips = require('mixins/UserTooltips');

var File = require('models/File');

var tmplFilesUser = require('files/FilesUser.tmpl');

//TableView
module.exports = Backbone.Marionette.LayoutView.extend(_.extend({}, UserTooltips, {
	table: null,
	baseElement: '',
	url: '',
	hasCheckbox: true,
	templates: {
		filesUser: tmplFilesUser
	},
	unbindFileMetadata: function() {
		$(this.baseElement).off();
		this.$el.off();
		this.lastRowChild = null;
		this.table = null;
	},
	bindFileMetadata: function() {
		var that = this;
		var baseEl = $(that.baseElement);

		var selector = 'tr.even td:not(:first-child), tr.odd td:not(:first-child)';
		if (!that.hasCheckbox) {
			selector = 'tr.even td, tr.odd td';
		}

		$(that.baseElement).on("click", selector, function(event) {
			var currentTarget = $(event.currentTarget);
			var target = $(event.target);

 			if(target.is("a.file") ||
 				target.is('span.project')) {
 				return;
 			}

			var table = that.table;

			var el = currentTarget.parents('tr');
			var nTr = el[0];
			var row = table.row(nTr);

			if ($(nTr).hasClass('expanded')) {
				console.log('row is already expanded');

				app.selected.file = {};

				if (app.views.fileMetadata) {
					app.views.fileMetadata.destroy();

				}
				row.child.hide();
				row.child.remove();

				$(nTr).removeClass('expanded');

			} else {

				//clicked on another file row while file metadata was expanded
				if (app.views.fileMetadata) {
					app.views.fileMetadata.destroy();

					that.lastRowChild.hide();
					that.lastRowChild.remove();

					that.$el.find('.expanded').removeClass('expanded');
				}

				var el = $('<div class="file-metadata metadata"></div>');
				row.child( el ).show();
				that.lastRowChild = row.child();

				$(nTr).addClass('expanded');

				app.selected.file = that.collection.get(nTr.id);
/*
				app.views.fileMetadata = new FileMetadataView({
					id: nTr.id,
					el: el,
					baseElement: that.baseElement
				});
*/
			}

		});
	},
	scrollToRow: function(id) {
		$('html, body').animate({
			scrollTop: $("#" + id).offset().top-90
		}, 400);
	},
	fnFormatDetails: function(nTr) {
		return "<div class='details metadata view'></div>";
	},
	getSelectedFiles: function() {
		var fileIDs = [];
		$(this.baseElement + ' td input[type=checkbox]:checked').map(function() {
			fileIDs.push(this.parentNode.parentNode.id);
		});
		return fileIDs;
	},
	bindCheckboxes: function() {

		//clicking on parent TD toggles checkbox
		$("input[type=checkbox]").parent('td').click(function(event) {
			var input = $(event.target).children('input[type=checkbox]:first-child');
			input.prop("checked", !input.prop("checked"));

		});

		//shift key multi-select
		app.widgets.lastChecked = null;
		$('input[type=checkbox]').click(function(event) {

			if (!app.widgets.lastChecked) {
				app.widgets.lastChecked = this;
				return;
			}

			if (event.shiftKey) {
				var start = $('input:checkbox').index(this);
				var end = $('input:checkbox').index(app.widgets.lastChecked);

				for (i = Math.min(start, end); i <= Math.max(start, end); i++) {
					$('input:checkbox')[i].checked = app.widgets.lastChecked.checked;
				}
			}

			app.widgets.lastChecked = this;
		});
	}, //bindCheckboxes
	getFile: function(row) {

		var params = {
			id: row[0],
			uuid: row[1],
			user: row[2],
			fileName: row[3],
			size: row[4],
			userFullName: row[5],
			lastModified: row[6],
			lastModifiedRaw: row[7],
			metadataId: row[8],
			relations: row[9],
			shapefileStatus: row[10]
		};
		return new File(params);
	},
	checkPaginationVisibility: function(settings) {
		var parent = this.$el.parent();

		if (Math.ceil((settings.fnRecordsDisplay()) / settings._iDisplayLength) > 1)  {
			parent.find('.dataTables_paginate').css("display", "block");
			parent.find('.dataTables_info').css("display", "block");
		} else {
			parent.find('.dataTables_paginate').css("display", "none");
			parent.find('.dataTables_info').css("display", "none");
		}
	},
	bindViewUser: function() {
		var that = this;

		this.$('a.user').click(function(event) {
			event.preventDefault();
			event.stopPropagation();

			var href = $(event.currentTarget).attr('href');
			app.router.navigate(Mustache.to_html(href.substring(1)), true);
		});

		var anchors = this.$el.find('a.user');
		anchors.each(function() {

			var el = $(this);
			var id = el.attr('data-user');

			UserTooltips.showUserTooltip(el, id);
		});

	},
	bindToolbar: function() {
		$("#trash_wrapper div.toolbar").prepend($('#trashControls'));
	},
	/*convenience methods for children */
	uncheck: function() {
		var el = $('#toggleSelected');
		el.attr('checked', false);
		jQuery.data(el[0], 'checked', false);

		$(".files input[type=checkbox]").prop("checked", false);
	},
	toggleSelected: function() {
		var el = $('#toggleSelected');
		var checked = el.data('checked');

		$(".files td input[type=checkbox]").attr("checked", !el.data('checked'));
		jQuery.data(el[0], 'checked', !checked);

		if (app.views.fileMove) {
			app.views.fileMove.updateSelectedCount();
		}
	}
})); //TableView