module.exports = {
	//collection names
	PROJECTS: "projects",
	CAMPAIGNS: "campaigns",
	ORGANIZATIONS: "orgs",

	models: {},
	get: function(key) {
		var collection = this.models[key];

		if (!collection) {
			collection = new Backbone.Collection();
		}

		return collection;
	},
	set: function(key, collection) {
		this.models[key] = collection;
	},
	has: function(key) {
		if (this.models[key]) {
			return true;

		} else return false;
	}
}