//UserRoutes
module.exports = {
	profile: function(name) {
		return Mustache.render("u/{{name}}", {
			name: name
		});
	},
	editProfile: function(name) {
		return Mustache.render("u/{{name}}/edit", {
			name: name
		});
	},
	editProfileAddress: function(name, id) {
		return Mustache.render("u/{{name}}/edit/address/{{id}}", {
			name: name,
			id: id
		});
	},
	editProfilePosition: function(name, id) {
		return Mustache.render("u/{{name}}/edit/position/{{id}}", {
			name: name,
			id: id
		});
	},
	editPicture: function(name) {
		return Mustache.render("u/{{name}}/edit/picture", {
			name: name
		});
	},
	editBackground: function(name) {
		return Mustache.render("u/{{name}}/edit/background", {
			name: name
		});
	},
	feedback: function() {
		return 'feedback';
	},
	resetPassword: function() {
		return "/login/changePassword"
	},
	console: function() {
		return 'console';
	}
}