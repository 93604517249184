// ReportSubmissionsAPI
module.exports = {
  // Routed through reports
  getReportsStatusBreakdown(orgId, userId) {
    return Mustache.render(
      '//{{{reportsUrl}}}/reports/api/org/{{orgId}}/overview',
      {
        orgId,
        reportsUrl: window.location.host
      }
    );
  },

  getProjectsList(orgId, userId, reportCycle, status) {
    return Mustache.render(
      '//{{{reportsUrl}}}/reports/api/org/{{orgId}}/reportCycle/{{reportCycle}}/status/{{status}}/projects',
      {
        orgId,
        userId,
        reportCycle,
        status,
        reportsUrl: window.location.host
      }
    );
  },

  getProjectReportCycles(orgId, projectId) {
    return Mustache.render(
      '//{{{reportsUrl}}}/reports/api/org/{{orgId}}/project/{{projectId}}/reportCycles',
      {
        orgId,
        projectId,
        reportsUrl: window.location.host
      }
    );
  },
  getReportData(reportCycle, projectId, orgId) {
    return Mustache.render(
      '//{{{reportsUrl}}}/reports/api/org/{{orgId}}/project/{{projectId}}/reportCycle/{{reportCycle}}/reportHistory',
      {
        projectId,
        reportCycle,
        reportsUrl: window.location.host,
        orgId
      }
    );
  },
  getComments(projectId, reportCycle, type, id, orgId) {
    return Mustache.render(
      '//{{{reportsUrl}}}/reports/api/org/{{orgId}}/project/{{projectId}}/reportCycle/{{reportCycle}}/{{type}}/{{id}}/comments',
      {
        projectId,
        reportCycle,
        type,
        id,
        orgId,
        reportsUrl: window.location.host
      }
    );
  },
  getAttachments(projectId, reportCycle, type, id, orgId) {
    return Mustache.render(
      '//{{{reportsUrl}}}/reports/api/org/{{orgId}}/project/{{projectId}}/reportCycle/{{reportCycle}}/{{type}}/{{id}}/attachments',
      {
        projectId,
        reportCycle,
        type,
        id,
        orgId,
        reportsUrl: window.location.host
      }
    );
  },

  uploadFile(projectId, reportCycle) {
    return Mustache.render(
      '//{{{reportsUrl}}}/reports/reportCycle/{{reportCycleKey}}/project/{{projectId}}/upload',
      {
        projectId,
        reportCycleKey: reportCycle,
        reportsUrl: window.location.host
      }
    );
  },

  addComment(type, reportId) {
    return Mustache.render(
      '//{{{reportsUrl}}}/reports/api/project/{{type}}/{{reportId}}/comment',
      {
        type,
        reportId,
        reportsUrl: window.location.host
      }
    );
  },

  getReportStatus(orgId, type, reportId, reportCycle) {
    return Mustache.render(
      '//{{{reportsUrl}}}/reports/api/org/{{orgId}}/{{type}}/{{reportId}}/reportCycle/{{reportCycle}}/status',
      {
        orgId,
        type,
        reportId,
        reportCycle,
        reportsUrl: window.location.host
      }
    );
  },

  destroyReport(orgId, projectId, reportId, type) {
    return Mustache.render(
      '//{{{reportsUrl}}}/reports/api/org/{{orgId}}/project/{{projectId}}/{{type}}/{{reportId}}/destroy',
      {
        orgId,
        type,
        reportId,
        projectId,
        reportsUrl: window.location.host
      }
    );
  },

  addAttachment(type, reportId) {
    return Mustache.render(
      '//{{{reportsUrl}}}/reports/api/project/{{type}}/{{reportId}}/attachment',
      {
        type,
        reportId,
        reportsUrl: window.location.host
      }
    );
  },

  removeAttachment(orgId, attachmentId) {
    return Mustache.render(
      '//{{{reportsUrl}}}/reports/api/org/{{orgId}}/attachment/{{attachmentId}}/delete',
      {
        orgId,
        attachmentId,
        reportsUrl: window.location.host
      }
    );
  },

  getMostRecentReportCycle(orgId) {
    return Mustache.render(
      '//{{{reportsUrl}}}/reports/api/org/{{orgId}}/current/reportCycle',
      {
        orgId,
        reportsUrl: window.location.host
      }
    );
  },

  templateList(orgId) {
    return Mustache.render(
      '//{{{reportsUrl}}}/reports/api/org/{{orgId}}/templateList',
      {
        orgId,
        reportsUrl: window.location.host
      }
    );
  },

  addTemplate(orgId) {
    return Mustache.render(
      '//{{{reportsUrl}}}/reports/api/org/{{orgId}}/template',
      {
        orgId,
        reportsUrl: window.location.host
      }
    );
  },

  deleteTemplate(orgId, templateId) {
    return Mustache.render(
      '//{{{reportsUrl}}}/reports/api/org/{{orgId}}/template/{{templateId}}/delete',
      {
        orgId,
        templateId,
        reportsUrl: window.location.host
      }
    );
  },

  orgReportCycleSchedule(orgId) {
    return Mustache.render(
      '//{{{reportsUrl}}}/reports/api/org/{{orgId}}/reportCycleSchedule',
      {
        orgId,
        reportsUrl: window.location.host
      }
    );
  },

  downloadTemplate(orgId, templateId) {
    return Mustache.render(
      '//{{{reportsUrl}}}/reports/api/org/{{orgId}}/template/{{templateId}}',
      {
        orgId,
        templateId,
        reportsUrl: window.location.host
      }
    );
  },

  getReportPeriods(orgId, showCompleted) {
    return Mustache.render(
      '//{{{reportsUrl}}}/reports/api/org/{{orgId}}/report-periods?displayCompletedProjects={{showCompleted}}',
      {
        orgId,
        reportsUrl: window.location.host,
        showCompleted
      }
    );
  },

  saveReportPeriod(orgId, projectId) {
    return Mustache.render(
      '//{{{reportsUrl}}}/reports/api/org/{{orgId}}/project/{{projectId}}/period',
      {
        orgId,
        projectId,
        reportsUrl: window.location.host
      }
    );
  },
  saveReportPeriods(orgId) {
    return Mustache.render(
      '//{{{reportsUrl}}}/reports/api/org/{{orgId}}/projects/period',
      {
        orgId,
        reportsUrl: window.location.host
      }
    );
  },

  email(orgId) {
    return Mustache.render('//{{{reportsUrl}}}/reports/api/email/', {
      orgId,
      reportsUrl: window.location.host
    });
  },

  getProjectsEmailAddresses(orgId, reportCycle, status, projects, userIds) {
    return Mustache.render(
      '//{{{reportsUrl}}}/reports/api/org/{{orgId}}/status/{{status}}/email/addresses?projects={{projects}}&userIds={{userIds}}',
      {
        orgId,
        status,
        projects,
        userIds,
        reportsUrl: window.location.host
      }
    );
  },

  getProjectContacts(projectId) {
    return Mustache.render(
      '//{{{reportsUrl}}}/reports/api/project/{{projectId}}/projectContacts',
      {
        projectId,
        reportsUrl: window.location.host
      }
    );
  },

  updateProjectContacts(projectId) {
    return Mustache.render(
      '//{{{reportsUrl}}}/reports/api/project/{{projectId}}/projectContacts/update',
      {
        projectId,
        reportsUrl: window.location.host
      }
    );
  },

  // Routed through Workspace
  getProjectReportGroup(projectId) {
    return Mustache.render(
      '/api/report-submissions/project/{{projectId}}/group',
      {
        projectId
      }
    );
  },

  getProjectName(orgId, projectId) {
    return Mustache.render(
      '/api/org/{{orgId}}/project/{{projectId}}/metadata',
      {
        projectId,
        orgId
      }
    );
  }
};
