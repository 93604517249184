//Background
module.exports = {
	image: null,
	setRandom: function() {
		var that = this;

		log.debug('Background.setRandom');

		that.hasBackground = true;

		if (that.cachedImage) {
			that.setBackground(that.cachedImage);

		} else {
			Modernizr.on('webp', function (supported) {

				if (!that.hasBackground) {
					return;
				}

				that.loadBackground({
					webp: supported
				});
			});
		}
	},
	loadBackground: function(options) {
		var that = this;

		var image = this.getImage(options);

		$('<img/>').attr('src', image.url).on('load', function() {
		   $(this).remove(); // prevent memory leaks as @benweet suggested

		   if (!that.hasBackground) {
		   	//if we've entered into a view w/no background
		   	//since first image request, do nothing
		   	return;
		   }

		   that.setBackground(image);
		});
	},
	setBackground: function(image) {
		var body = $('body');

		body.css('background', image.background);
		body.css('background-size', 'cover');

		body.removeClass('tx_bg');

		this.cachedImage = image;
	},
	getImage: function(options) {

		var images = [
			{name:'21622549191_890ed3a6c6_k', opacity: '0.5'},
			{name:'19001654190_8dc7c25d8e_k', opacity: '0.7'},
			{name:'16017540416_88f2662539_k', opacity: '0.7'},
			{name:'28400985025_21c8ca2219_k', opacity: '0.6'},
			{name:'30487084693_ec866748bf_k', opacity: '0'},
			{name:'14895932446_e7d6af08e8_k', opacity: '0.7'},
			{name:'12584824925_20016c2f2e_k', opacity: '0.5'},
			{name:'12584825825_5d323ee2ee_k', opacity: '0.7'},
			{name:'10960980923_51af89f7db_k', opacity: '0.5'},
			{name:'9160033644_98f872adb1_h', opacity: '0.5'},
			{name:'21602411252_6ad4724115_h', opacity: '0.7'},
			{name:'20990417244_b663758001_k', opacity: '0.7'},
			{name:'17024890179_9fcd391804_k', opacity: '0.7'},
			{name:'21424835148_9066b320ba_k', opacity: '0.7'},
			{name:'4371010590_4dcb0edac1_o_2048', opacity: '0.7'},
			{name:'42520394571_1f1a51446b_o', opacity: '0.5'},
			{name:'40717156284_39ac142fb3_k', opacity: '0.7'},
			{name:'40652301032_3bb36dcc83_k', opacity: '0.5'},
			{name:'37395613771_51d0c45613_k', opacity: '0.5'},
			{name:'37354881711_fe71c26a1c_k', opacity: '0.7'},
			{name:'36398439270_3255067da2_o', opacity: '0.7'},
			{name:'38557167015_a9eec194d7_o', opacity: '0.7'},
			{name:'37572624971_ebb6ae9177_k', opacity: '0.5'}  
		];

		var dir = '/public/images/backgrounds/';
		var extension = '.jpg';

		if (options.webp) {
			dir = '/public/images/backgrounds/webp/';
			extension = '.webp';
		}

		//get random image if no image loaded yet
		var image = this.image;
		if (image == null) {
			var index = _.random(0, (images.length-1));
			image = images[index];
		}

		image.url = dir + image.name + extension;

		var template = "linear-gradient(rgba(0, 0, 0, {{opacity}}), rgba(0, 0, 0, {{opacity}})),url('{{{url}}}') no-repeat center center";
		image.background = Mustache.render(template, image);

		return image;
	},
	removeBackground: function() {
		var body = $('body');

		body.removeAttr('style');
		body.addClass('tx_bg');

		this.hasBackground = false;
	}
};