// CampaignRoutes
module.exports = {
  createCampaign() {
    return 'campaigns/create';
  },
  campaigns(type) {
    let url = 'campaigns';

    if (!type) {
      return url;
    }
    url += `?type=${type}`;

    return url;
  },
  campaign(id, name) {
    return Mustache.render('campaign/{{id}}/{{name}}', {
      id,
      name
    });
  },
  campaignAbout(id, name) {
    return Mustache.render('campaign/{{id}}/{{name}}/about', {
      id,
      name
    });
  },
  campaignSettings(id, name) {
    return Mustache.render('campaign/{{id}}/{{name}}/settings', {
      id,
      name
    });
  },
  campaignArchives(id, name) {
    return Mustache.render('campaign/{{id}}/{{name}}/archives', {
      id,
      name
    });
  },
  campaignInvitations(id, name) {
    return Mustache.render('campaign/{{id}}/{{name}}/invitations', {
      id,
      name
    });
  },
  campaignSettingsAddMembers(id, name) {
    return Mustache.render('campaign/{{id}}/{{name}}/settings/add-members', {
      id,
      name
    });
  },
  campaignSettingsAddOrgs(id, name) {
    return Mustache.render(
      'campaign/{{id}}/{{name}}/settings/add-organizations',
      {
        id,
        name
      }
    );
  },
  campaignContacts(id, name) {
    return Mustache.render('campaign/{{id}}/{{name}}/contacts', {
      id,
      name
    });
  },
  campaignMetadata(id, name) {
    return Mustache.render('campaign/{{id}}/{{name}}/metadata', {
      id,
      name
    });
  },
  campaignNotices(id, name) {
    return Mustache.render('campaign/{{id}}/{{name}}/notices', {
      id,
      name
    });
  },
  campaignArchiveSettings(id, name) {
    return Mustache.render('campaign/{{id}}/{{name}}/archive-settings', {
      id,
      name
    });
  },
  campaignEmbargoes(id) {
    return Mustache.render('campaign/{{id}}/embargoes', {
      id
    });
  }
};
