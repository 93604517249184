var File = require('models/File');

module.exports = Backbone.Collection.extend({ 
	model: File,
	removeAll: function(ids) {
		var that = this;
		$.each(ids, function(k, fileId){
			that.remove(that.get(fileId));
		});
	}
});