var ProjectRoutes = require('routes/ProjectRoutes');

//ContextMenu
module.exports = {
	destroy: function() {
		$.contextMenu('destroy');
	},
	bindMenu: function(options) {
		var that = this;

		if (options.el) {
			//generate unique value
			var clazz = "menu-" + Date.now() + Math.random().toString(36).substr(2, 9);

			$(options.el).addClass(clazz);

			options.selector = "." + clazz;
		}

		options.zIndex = 100;

		//add customIcon handler
		if (options.items) {
			_.each(options.items, function(item) {
				if (item && item.materialIcon) {
					item.icon = that.customIcon;
				}
			});
		}

		//don't clutter up DOM, destroy elements after close
		options.dynamic = true;

		var callback = options.callback;
		if (options.dynamic) {

			_.extend(options, {
				selector: options.selector,
				trigger: options.trigger,
				className: options.className,

		        items: options.items,
		        //seems mostly useless, doesn't fire after el insert
				build: function($trigger, e) {
					// log.debug('running build');

					if (callback) {
						callback($trigger,e);
					}
					

					return {
						callback: function(key, options) {
							log.debug('callback');

		                    var m = "clicked: " + key;
		                    window.console && console.log(m) || alert(m); 
		                }
					}
				}
			});
		}

		$.contextMenu(options);

		return options;
	},
	customIcon: function(opt, $itemElement, itemKey, item){
        var tmpl = '<i class="material-icons">{{icon}}</i> {{title}}';
		var html = Mustache.render(tmpl, {
			icon: item.materialIcon,
			title: item.name
		});

        $itemElement.html(html);

        return 'context-menu-icon-' + item.materialIcon;
    }
}