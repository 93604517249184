var ParentView = require('mixins/ParentView');
var UserRoleView = require('mixins/UserRoleView');
var ContextMenu = require('mixins/ContextMenu');
var ProjectCloner = require('mixins/ProjectCloner');

var routes = require('routes/ProjectRoutes');

var Events = require('events/projects/ProjectEvents');
var PageEvents = require('events/PageEvents');

var Folder = require('models/Folder');
var Projects = require('models/Projects');

var api = require('api/ProjectAPI');

var tmplUserRoleLevel = require('permissions/user/UserRoleLevel.tmpl');
var tmplProjectHeader = require('projects/ProjectHeader.tmpl');

var reportSubmissionsAPI = require('api/ReportSubmissionsAPI');

require('context-menu.styl')

//ProjectTitleView
module.exports = Backbone.Marionette.ItemView.extend(_.extend({}, UserRoleView, {
	template: tmplProjectHeader,
	className: 'rw-header outer',
	wrapperEl: '#projectTitleStickyWrapper',
	ui: {
		usrRoleLevel: 'div.role-level',
		confirmDelete: 'div.dlgProjectDelete'
	},
	events: {
		'click a.back': 'goBack',
		'click a.files': 'viewFiles',
		'click a.metadata': 'viewMetadata',
		'click a.activity': 'viewActivity',
		'click a.archives': 'viewArchives',
		'click a.assets': 'viewAssets',
		'click a.settings': 'viewSettings',
		'click a.reports': 'viewReports',
		'click li.more-actions': 'viewMoreActions',
		'click a.help': 'startTutorial'
	},
	goBack: function(event) {
		if(event) {
			event.preventDefault();
		}

		var that = this;

		if (app.lastViews.length) {
			var lastView = app.lastViews.pop();

			//update back text w/new destination while cursor still hovering over button
			//prevent flicker navigating back to campaigns, orgs
			if (lastView.name != 'campaign' && lastView.name != 'organization') {

				//delay to let page load from previous nav catch up
				_.delay(function() {
					//verify cursor still hovering on button
					if (that.$el.find('a.back').hasClass('hovered')) {
						that.updateBackText();
					}
					
				}, 500);
			}

			app.router.navigate(lastView.route, true);

		} else {
			app.router.navigate('projects', true);
		}
	},
	viewReports: function(event){
		event.preventDefault();
		app.router.navigate(routes.projectReport(app.selected.project.id), true);


	},
	viewFiles: function(event) {
		event.preventDefault();

		app.router.navigate(routes.files(app.selected.project.id), true);
	},
	viewActivity: function(event) {
		event.preventDefault();
		app.router.navigate(routes.projectActivity(app.selected.project.id), true);
	},
	viewArchives: function(event) {
		event.preventDefault();
		app.router.navigate(routes.projectArchives(app.selected.project.id), true);
	},
	viewAssets: function(event) {
		event.preventDefault();
		app.router.navigate(routes.projectAssets(app.selected.project.id), true);
	},
	viewSettings: function(event) {
		event.preventDefault();
		app.router.navigate(routes.projectSettings(app.selected.project.id), true);
	},
	addedArchiveFiles: function(data) {
		log.debug('addedArchiveFiles data: %o', data);
		this.$el.find('a.archive').html('Archive (' + data.total + ')');
	},
	showFolderTitle: function(folderId) {
		var that = this;

		//first verify that app.selected.folder is loaded

		if (!app.selected.folder || !app.selected.folder.id 
			|| (app.selected.folder.id != folderId)) {
			//if not, fetch model first

			var FolderModel = Backbone.Model.extend({
				urlRoot: '/api/folder'
			});

			var folder = new FolderModel({id: folderId})

			folder.fetch({
				success: function(model, response, options) {
			    	app.selected.folder = model;
			    	that.setFolderTitle();
				}
			});

		} else {
			this.setFolderTitle();
		}

	},
	setFolderTitle: function() {

		this.hideSubNav();

		var el = this.$el.find('.folder-title');
		el.show();

		var name = app.selected.folder.get('title');
		el.find('span.name').html(name);
	},
	showSubNav: function() {
		$('.rw-header ul.nav').show();

		this.bindMoreActions();
	},
	hideSubNav: function() {
		$('.rw-header ul.nav').hide();
	},
	hideFolderTitle: function() {
		var el = this.$el.find('.folder-title');
		el.hide();

		el.find('span.name').html('');

		//show subnav
		$('.rw-header ul.nav').show();
	},
	setName: function(name) {
		this.model.set('name', name);

		this.$el.find('span.title').html(this.model.get('name'));
	},
	selectTab: function(clazz) {

		//hide subnav for now, back button will return to files view
		if (clazz == 'metadata') {
			clazz = 'files';
		}

		this.$el.find('ul.nav li a').removeClass('selected');
		this.$el.find('ul.nav .'+ clazz).addClass('selected');

		this.selected = clazz;

		this.checkTutorialExists();
	},
	onDestroy: function() {
		$(this.wrapperEl).remove();

		ContextMenu.destroy();

		app.events.off(Events.showFolderTitle, this.showFolderTitle);
		app.events.off(Events.hideFolderTitle, this.hideFolderTitle);

		app.events.off(Events.showSubNav, this.showSubNav);
		app.events.off(Events.hideSubNav, this.hideSubNav);

		app.events.off(Events.savedName, this.setName);

		app.events.off(Events.addedArchiveFiles, this.addedArchiveFiles);
		app.events.off(PageEvents.changed, this.selectTab);
	},
	updateBackText: function() {
		this.$el.find('span.title').html(this.getBackText());
	},
	getBackText: function() {
		var text = 'Go back to your ';

		if (!app.lastViews.length) {
			text += 'projects.';
		} else {
			var lastView = app.lastViews[app.lastViews.length-1];
			text += lastView.name + '.';
		}

		return text;
	},
	viewMoreActions: function(event) {
		event.preventDefault();

		//create menu dynamically each time
		//to ensure menu is still bound despite subnav views changing
		$.contextMenu( 'destroy', 'li.more-actions i' );
		this.bindMoreActions();

		//show menu
		$('li.more-actions i').contextMenu();
	},
	bindMoreActions: function() {
		var that = this;

		var options= {
		    selector: "li.more-actions i",
		    trigger: 'none',
		    items: {/*
		        download: {
		        	name: "Download", 
		        	materialIcon: 'archive',
		        	callback: that.downloadProject
		        },*/
		        clone: {
		        	name: "Clone project", 
		        	materialIcon: 'content_copy',
		        	callback: _.bind(that.cloneProject, that)
		        },
		        metadata: {
		        	name: "View project metadata", 
		        	materialIcon: 'dashboard',
		        	callback: _.bind(that.viewProjectMetadata, that)
		        }
		    }
		}//options

		if (that.model.isOwned()) {
			options.items.sep1 = "---";
			options.items.delete = {
				name: "Delete project", 
				materialIcon: 'delete',
				callback: _.bind(that.deleteProject, that)
			}
		}

		options.determinePosition = function($menu){
	        $menu.css('display', 'block')
	            .position({ my: "left top", at: "center bottom", of: this})
	            .css('display', 'none');
    	}

		ContextMenu.bindMenu(options);
	},
	trackExternalArchive: function() {
		log.debug('add archive')

		app.router.navigate(routes.addExternalArchive(this.model.id), true);
	},
	cloneProject: function() {
		ProjectCloner.clone(this.model.id);
	},
	viewProjectMetadata: function() {
		app.router.navigate(routes.metadata(this.model.id), true);
	},
	deleteProject: function() {
		var that = this;

		this.ui.confirmDelete.dialog({
			resizable: false,
			modal: true,
			buttons: {
				"Delete": function() {
					var thisDialog = this;

					Projects.load(that.model.id).done(function( project ) {
						project.destroy({
							wait: true,
							success: function(model, response) {
								$(thisDialog).dialog("close");

								//clear projects cache
								app.lastViews = [];
								app.cache.projects = null;

								that.goBack();
							}
						});
					});

				},
				Cancel: function() {
					$(this).dialog("close");
				}
			}
		});
	},
	startTutorial: function(event) {
		event.preventDefault();

		var that = this;

		require.ensure([], function() {
			var UserGuide = require('util/UserGuide');

			
			var tutorial;

			switch(that.selected) {
				case 'files':
					tutorial = require('util/tutorials/ProjectFilesTutorial')
					break;
				case 'settings':
					tutorial = require('util/tutorials/ProjectSettingsTutorial')
					break;
				default:
					log.warn('unknown tutorial tab: %s', that.selected);
					return;
			}

			if (tutorial) {
				UserGuide.loadTutorial(tutorial);
			}
		});
	},
	showTutorialIndicator: function() {
		this.$el.find('a.help').show();
	},
	hideTutorialIndicator: function() {
		this.$el.find('a.help').hide();
	},
	hasTutorial: function(selected) {

		var tutorials = ['files','settings'];

		return _.contains(tutorials, selected);
	},
	checkTutorialExists: function() {
		// log.debug('checking if hasTutorial for %s: %s', this.selected, this.hasTutorial(this.selected))

		if (this.hasTutorial(this.selected)) {
			this.showTutorialIndicator();

		} else this.hideTutorialIndicator();
	},
	onShow: function() {
		var that = this;

		if (this.selected) {
			this.selectTab(this.selected);

			this.checkTutorialExists();
		}

		if (!this.headerInitialized) {
			this.$el.show();
			this.$el.sticky({topSpacing:0});
			this.headerInitialized = true;
			this.$wrapperEl = $(this.wrapperEl);

		} else {
			this.$wrapperEl.show();
		}

		if (this.model.isOwned()) {
			this.$el.find('li.settings').show();
			this.$el.find('li.archive').show();
		}

		this.setUserRoleIndicator(this.model);

		_.delay(function() {
			that.$el.find('a.back').mouseenter(function() {
				$(this).addClass('hovered');

				that.$el.find('span.title').html(that.getBackText());
				that.$el.find('ul.nav li').css('visibility', 'hidden');
			})
			.mouseleave(function() {
				$(this).removeClass('hovered');

				that.$el.find('span.title').html(that.model.get('name'));
				that.$el.find('ul.nav li').css('visibility', 'visible');
			});
		}, 700);

		app.events.on(Events.addedArchiveFiles, this.addedArchiveFiles, this);

		app.events.on(Events.showSubNav, this.showSubNav, this);
		app.events.on(Events.hideSubNav, this.hideSubNav, this);

		app.events.on(Events.savedName, this.setName, this);

		app.events.on(Events.showFolderTitle, this.showFolderTitle, this);
		app.events.on(Events.hideFolderTitle, this.hideFolderTitle, this);

		app.events.on(PageEvents.changed, this.selectTab, this);
	},
	initialize: function(options) {
		log.debug('ProjecttitleView.initialize');
		// log.debug('projecttitleview model :%o', options.model.toJSON());

		this.model = options.model;
		this.selected = options.selected;

		this.api = api;
	}
}));