//ParentView
module.exports = {
	destroy: function() {
		var that = this;

		if (that.onDestroy) {
			that.onDestroy();
		}

		for (var key in that.views) {
		   if (that.views.hasOwnProperty(key) && that.views[key] instanceof Backbone.View) {
		   		var view = that.views[key];

		   		log.info('view %s %o is a view', key, view);

		   		if (typeof view !== 'undefined') {
		   			if (typeof view.destroy == 'function') { 
						view.destroy();
					} else {
						//console.log('removing view %o in %o', view, that);
						try {
							view.remove();

						} catch(e) {
							log.error(e);
							//debugger;
						}
					}
		   		} else {
		   			console.log('found null view in %o', that);
		   		}

		   }
		}

		//console.log('removing parent view: %s, %o', view.$el[0], view);
		this.remove();
	},
};