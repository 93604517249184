var Routing = require('routers/Routing');

var Archive = require('models/Archive');

//ArchiveRouter
module.exports = Backbone.Router.extend({
	routes: {
		'archive/:id/submit': 'reviewSubmission',
	},
	reviewSubmission: function(id) {
		var name = 'rossdm'

		log.debug('reviewSubmisdsion')

		require.ensure([], function() {
			var ArchiveFolderView = require('views/archives/ArchiveFolderView');
			var User = require('models/User');

			var model = new Archive({
				id: id
			});
			var view = new ArchiveFolderView({
				model: model
			});

			model.on("sync", function() {
				$(document).scrollTop(0);
				app.m.getRegion('main').show(view);
			});

			model.fetch();

		}); //require.ensure
	}
}); //router