var Project = require('./Project');
var Models = require('models/Models');

var api = require('api/ProjectsAPI');

module.exports = Backbone.PageableCollection.extend({
	 // url: "/api/projects",
	model: Project,
	queryParams: {
		facets: null
	},
	setLabels: function(labelFacets) {
		var nameFacet = _.find(this.facets, function(facet) {
				if (_.has(facet, "name")) {
					return true;
				} else return false;
			});

		if (nameFacet) {
			labelFacets.push(nameFacet);
		}

		this.setFacets(labelFacets);
	},
	setFacets: function(facets) {
		this.facets = facets;
		this.queryParams.facets = JSON.stringify(facets);
	},
	getFacets: function() {
		return this.facets;
	},
	hasFacets: function() {
		if (this.facets && this.facets.length) {
			return true;
		} else return false;
	},
	clearFacets: function() {
		this.facets = null;
		this.queryParams.facets = null;
	},
	setName: function(field) {
		var facets = this.getFacets();

		if (facets && facets.length) {
			var fieldSet = false;
			_.each(facets, function(facet) {
				if (_.has(facet, "name")) {
					facet.name = field;
					fieldSet = true;
				}
			})

			if (!fieldSet) {
				facets.push({name: field});
			}

		} else {
			var facet = {name: field};

			if (this.hasFacets()) {
				facets.push(facet);
			} else {
				facets = [facet];
			}
		}

		this.setFacets(facets);
	},
	setSort: function(field) {
		this.queryParams.sort = field;
	},
	setSortDir: function(field) {
		this.queryParams.sortDir = field;
	},
	clearSort: function() {
		this.queryParams.sort = null;
	},
	url: function() {
		var url = api.getUserProjects();
		if (this.campaignId) {
			url = api.getCampaignProjects(this.campaignId);

		} else if (this.orgId) {
			url = api.getOrgProjects(this.orgId);
		}

		return url;
	},
	parse: function (response) {
	   	//log.debug('projects.parse: %o', response);

		var projects = response.projects;

		//log.debug('total: %s', response.total);

		var perPage = 25;

		this.state.totalPages = Math.ceil(response.total / perPage);
		this.state.campaigns = response.campaigns;
		this.state.orgs = response.orgs;
		this.state.labels = response.labels;

		//log.debug('totalPages is: %s', this.state.totalPages);

		return projects;
	},
	findTag: function(query) {
		var models = this.filter(function(model) {
			//use intersection instead of indexof
			return (_.pluck(model.get("tags"), 'name').indexOf(query) >= 0);
		});

		return new Projects(models);
	},
	findName: function(query) {
		var models = this.filter(function(model) {
			return (model.get("name").indexOf(query) >= 0);
		});

		return new Projects(models);
	},
	test: function() {
		return this.filter(function(todo) {
			return todo.get('fileCount');
		});
	},
	filterName: function(query) {
		return _(this.models.filter(function(model) {
			return model.get("name").indexOf(query) >= 0;
		}));
	},
	initialize: function(options) {
		options || (options = {});
		this.campaignId = options.campaignId;
		this.orgId = options.orgId;
	}
},{
	fromFile: function(id) {
		var that = this;
		var deferred = $.Deferred();

		var url = Mustache.render("/api/project/for/file/{{id}}", {
			id: id
		});

		$.ajax({
			url: url,
			success: function(json) {
				var project = new Project(json);
				that.setSelected(project);

				deferred.resolveWith(this, [project]);
			}
		});

		return deferred.promise();
	},
	/*
	* returns a Project from the collection or fetches it remotely
	* and sets it as app.selected.project
	*/
	load: function(id) {
		var that = this;
		var deferred = $.Deferred();

		that.lookup(id).done(function( project ) {
			app.selected.project = project;

			var projects = app.models.get(Models.PROJECTS);
			if (!projects.get(id)) {
				projects.add(app.selected.project);
			}

			app.models.set(Models.PROJECTS, projects);

			deferred.resolveWith(this, [project]);
		});

		return deferred.promise();
	},
	setSelected: function(project) {
		app.selected.project = project;
		var projects = app.models.get(Models.PROJECTS);
		if (!projects.get(project.id)) {
			projects.add(app.selected.project);
		}
	},
	/*
	* returns a Project from the collection or fetches it remotely
	*/
	lookup: function(id) {
		var model;
		var deferred = $.Deferred();

		if (model = app.models.get(Models.PROJECTS).get(id)) {
			deferred.resolveWith(this, [model]);
		} else {
			model = new Project({id: id});
			model.fetch({
				success: function(model, response, options) {
			    	deferred.resolveWith(this, [model]);
				}
			});
		}

		return deferred.promise();
	},
	forUser: function(id) {
		var models = this.filter(function(model) {

			if (model.has('contacts')) {
				var contacts = null;

			if (model.get('contacts') instanceof Backbone.Collection) {
					contacts = model.get('contacts').toJSON();
				} else {
					contacts = model.get('contacts');
				}

				for (var i = 0; i < contacts.length; i++) {
					var contact = contacts[i];
					var user = contact.user;

					if (user != null) {
						if (user.id == id) {
							return true;
						}
					}
				}

			}
			return false;
		});

		return new Projects(models);
	},
	search: function(query, tags) {
		console.log('projects search - tags: ' + tags);

		//if tag
		if (_.indexOf(tags, query) >= 0) {
			query = null;
		}

		var models = this.filter(function(model) {

			if (query == null) {
				if (_.intersection(_.pluck(model.get("tags"), 'name'), tags).length == tags.length) {
					return true;
				} else {
					return false;
				}
			}

			//if name not null -> if tags then must have tag
			if (model.get("name").toLowerCase().indexOf(query.toLowerCase()) >= 0) {
				if (tags != null && tags.length > 0) {
					//has tag - return true
					//else has name but not tag - return false
					if (_.intersection(_.pluck(model.get("tags"), 'name'), tags).length == tags.length) {
						return true;
					} else return false;

				}

				return true;
			}

			return false;
		});

		return new Projects(models);
	}
});