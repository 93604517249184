//EditorEvents
module.exports = {
	savedNewFile: 'saved-new-file',
	renamedFile: 'renamed-file',
	savedFile: "saved-file",
	saveFile: "save-file",
	cleanNotSaved: "clean-not-saved",
	openRevision: "open-revision",
	gotoLine: "goto-line",
	historyExpanded: "history-expanded",
	historyCollapsed: "history-collapse",
	refreshHistory: "refresh-history",
	diffsExpanded: "diffs-expanded",
	diffsCollapsed: "diffs-collapsed",
	closeDiffs: "close-diffs",
	readonly: "readonly-file",
	dirtyFile: "dirty-file",
	getFileName: "get-file-name",
	setFileName: "set-file-name",
	getNotebookKey: "get-notebook-key",
	getNotebookFrame: "get-notebook-frame",
	notebookTimedOut: "notebook-timed-out",
	notebookLocked: "notebook-locked",
	notebookRunning: "notebook-running",
	notebookLoading: "notebook-loading",
	notebookLoaded: "notebook-loaded",
	notebookSaveAndClose: "notebook-save-and-close",
	editMode: "edit-mode",
	previewMode: "preview-mode",
    setImage: 'set-image',
    selectEditMode: 'select-edit-mode'
}
