var Organization = require('./Organization');
var Models = require('models/Models');

module.exports = Backbone.PageableCollection.extend({
	url: "/api/orgs",
	model: Organization,
	findName: function(query) {
		var models = this.filter(function(model) {
			return (model.get("name").indexOf(query) >= 0);
		});

		return new Organizations(models);
	}},{
	/*
	* returns a Organization from the collection or fetches it remotely
	* and sets it as app.selected.org
	*/
	load: function(id) {
		var that = this;
		var deferred = $.Deferred();

		that.lookup(id).done(function( org ) {
			app.selected.org = org;

			var orgs = app.models.get(Models.ORGANIZATIONS);
			if (!orgs.get(id)) {
				orgs.add(app.selected.org);
			}
			app.models.set(Models.ORGANIZATIONS, orgs);
			
			deferred.resolveWith(this, [org]);
		});

		return deferred.promise();
	},
	/*
	* returns a Organization from the collection or fetches it remotely
	*/
	lookup: function(id) {
		var model;
		var deferred = $.Deferred();

		if (model = app.models.get(Models.ORGANIZATIONS).get(id)) {
			deferred.resolveWith(this, [model]);
		} else {
			model = new Organization({id: id});
			model.fetch({
				success: function(model, response, options) {
			    	deferred.resolveWith(this, [model]);
				}
			});
		}

		return deferred.promise();
	}
});