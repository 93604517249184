//LoginDropdownView
module.exports = Backbone.View.extend({
	events: {
		'click a.googleLogin': 'googleLogin',
		'click a.loginSubmit': 'passwordLogin'
	},
	googleLogin: function(event) {
		event.preventDefault();

		log.info('google login');
		this.oauthLogin('/google/login');

	},
	oauthLogin: function(path) {
		var that = this;

	    $.oauthpopup({
	        path: path,
	        callback: function(){

	        	if(app.login.success) {
	        		if (app.login.welcome) {

	        			var user = app.login.user;
				        app.user.id = user.id;
						app.user.firstName = user.firstName;
						app.user.lastName = user.lastName;
						app.user.name = user.name;
	        			
	        			app.router.navigate("welcome", true);
	       
	        			that.remove();
	        			
	        		} else {
	        			window.location = '/projects'; 
	        		}
	        		
	        	} else if (app.login.invite == false) {
	        		alert('must be invited');
	        	}
	        	
	        }
	    });
		return false;
	},
	passwordLogin: function(event) {
		var that = this;
		event.preventDefault();
		$('.alert-message').hide();
		//$(event.target).parent().submit();
		var email = $('#regularLogin input.email').val();
		var password = $('#regularLogin input.password').val();

		var email = that.$el.find('input.email').val();
		var password = that.$el.find('input.password').val();

		$.post("/api/login", { email: email, password: password }, function(data) {

		  if (data.success) {
			  app.login.user.name = data.userName;
			  app.login.user.id = data.userId;
			  
			  //TODO:welcome flow
			  var newUser = false;
			  if (newUser) {
				  app.router.navigate("welcome", true);
			  } else {
				  window.location.reload();
			  }
			  
		  } else {
			//  $('#loginError').show();
			  console.log("invalid username or password");
		  }
		});
	},
	close: function() {
		$(document).off("click.login-outside");
		this.$el.find('form.sign-in').off('keypress');

		this.$el.hide();
		this.stopListening();
	},
	bindEvents: function() {
		var that = this;

		$(document).on("click.login-outside", function(event){
		    if(!$(event.target).parents().andSelf().is('div.login')){
		        // Click outside
		        that.close();
		    }
		});

		that.$el.find('form.sign-in').on('keypress', function(e) {
			  var code = (e.keyCode ? e.keyCode : e.which);
			  if(code == 13) { //Enter keycode
			    that.passwordLogin(e);
			  }
		  });
	},
	initialize: function() {
		log.info('LoginDropdownView initialize');

		this.$el.show();

		this.bindEvents();
	}

});