var Files = require('models/Files');

module.exports = Backbone.Model.extend({
	urlRoot: null,
	id: null,
	name: null,
	files: null,
	fileCount: 0,
	initialize: function() {
		this.urlRoot = Mustache.to_html("/project/{{id}}/folder", app.selected.project);
		this.set({
			files: new Files()
		});
	},
	resetFiles: function() {
		this.set('fileCount', null);
		this.get('files').reset();
	},
	removeFile: function(file) {
		this.get('files').remove(file.id);
		this.set({
			fileCount: this.get('fileCount') - 1
		}, {
			silent: true
		});
	},
	getFile: function(id) {
		return this.get('files').get(id);
	},
	addFile: function(file) {
		this.get('files').add(file);
		var count = this.get('fileCount');
		if (count == null) {
			count = 0;
		}

		this.set({
			fileCount: count + 1
		}, {
			silent: true
		});
	},
	getEncodedName: function() {
		return this.get("title").toLowerCase().replace(/ /g, '-').replace(/\//g, " ");
	},
	hasMetadata: function() {
		if (this.has('metadataid')) {
			return true;

		} else return false;
	},
	isPublishable: function() {
		if (this.has('publish') && this.get('publish')) {
			return true;
		}

		return this.hasPublishParent(this);
	},
	hasPublishParent: function(folder) {
		if (!folder) {
			folder = this;
		}
		
		if (folder.has('parent')) {
			var parentId = folder.get('parent');
			var parent = app.models.folders.get(parentId);

			if (parent.has('publish') && parent.get('publish')) {
				return true;

			} else return this.hasPublishParent(parent);

		} else return false;
	}
});