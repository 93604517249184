var OwnableModel = require('mixins/OwnableModel');

var routes = require('routes/ProjectRoutes');

//Project
module.exports = Backbone.Model.extend(_.extend({}, OwnableModel, {
	urlRoot: "/api/project",
	getFilesLabel: function() {
		return (this.get('fileCount') === 1) ? 'File' : 'Files';
	},
	toFullJSON: function() {
		var json = this.toJSON();

		if (this.get('contacts') instanceof Backbone.Collection) {
			json.contacts = this.get('contacts').toJSON();
		}

		if (json.contacts && json.contacts.length == 1 && json.contacts[0].user) {
			json.shortContactName = json.contacts[0].user.firstName;
		}

		return _.extend(json, {
			filesLabel: this.getFilesLabel(),
			filesUrl: routes.files(this.id),
			formattedUpdated: json.updated
		});
	},
	isArchivable: function() {
		if (this.has('archivePath') && this.get('archivePath')) {
			return true;
		} else return false;
	}
}));