//ProjectsAPI
module.exports = {
	getUserProjects: function() {
		return "/api/user/projects.json";
	},
	getCampaignProjects: function(id) {
		return Mustache.render("/api/campaign/{{id}}/projects.json", {
			id: id
		});
	},
	getOrgProjects: function(id) {
		return Mustache.render("/api/org/{{id}}/projects.json", {
			id: id
		});
	},
	getUserProjectMembers: function() {
		return "/api/user/projects/members.json";
	},
	getUserProjectCampaigns: function() {
		return "/api/user/projects/campaigns.json";
	},
	getUserProjectOrgs: function() {
		return "/api/user/projects/orgs.json";
	},
	getRecent: function() {
		return "/api/projects/recent.json";
	},

	getRecentFull: function() {
		return "/api/projects/recent-full.json";
	}
}