//CampaignsAPI
module.exports = {
	getCampaigns: function(type) {
		var url = "/api/campaigns/campaigns.json";

		if (!type) {
			return url;
		} else {
			url += "?type=" + type;
		}

		return url;
	},
	search: function(name) {
		return '/api/campaigns/search?name='+name;
	}
}