const api = require('api/UserAPI');

module.exports = Backbone.Model.extend({
  url() {
    let url = '';

    if (this.get('profile')) {
      url = api.getProfile(this.get('name'))
    } else {
      url = api.getEditProfile(this.get('name'))
    }

    return url;
  },
  getFullName() {
    return `${this.get('firstName')} ${this.get('lastName')}`;
  },
  getProfileJSON() {
    const json = this.toJSON();
    if (json.position == null && json.positions != null && json.positions.length > 0) {
      if (json.headline != null) {
        json.position = json.headline;
      } else {
        // json.position = json.positions[0];
        [json.position] = json.positions;
      }
    }

    if (json.address == null && json.addresses != null && json.addresses.length > 0) {
      //   json.address = json.addresses[0];
      [json.address] = json.addresses;
    }

    if (json.address) {
      if (json.address.phone || json.address.email) {
        json.hasAddressinfo = true;
      }
    }

    json.groupId = app.group.id;
    return json;
  },
}, {
  loggedIn() {
    if (!app.user.loggedIn()) {
      app.initialLocation = window.location.pathname;
      app.router.navigate('login', { trigger: true, replace: true });
      return false;
    } return true;
  },
});
