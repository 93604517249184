import * as BluePromise from 'bluebird';

const OrgRoutes = require('routes/OrgRoutes');
const Organizations = require('models/Organizations');
const Organization = require('models/Organization');

// OrganizationRouter
module.exports = Backbone.Router.extend(
  {
    routes: {
      'org/:id/metadata(/)(:page)(/)(:section)': 'metadata',

      'organizations/create': 'create',
      'org/:id/:name/contacts': 'contacts',

      'org/:id/settings': 'settings',
      'org/:id/invitations': 'invitations',

      'org/:id/:name/archives': 'archives',
      'org/:id/:name/archive-settings': 'archiveSettings',

      'org/:id/embargoes': 'embargoes',

      'org/:id/settings/pic': 'picSettings',
      'org/:id/settings/add-members': 'addMembers',

      'org/:id/:name': 'organization',

      organizations: 'organizations'
    },
    initialize() {
      this.bind('all', this.change);
    },
    create() {
      require.ensure([], function() {
        const OrgFormView = require('views/organizations/OrgFormView');
        app.m.getRegion('main').show(
          new OrgFormView({
            model: new Organization()
          })
        );
      });
    },
    contacts(id) {
      app.selected.org = {
        id
      };

      require.ensure([], function() {
        const ContactListView = require('views/contacts/ContactListView');
        const api = require('api/OrgContactsAPI');

        const model = new Backbone.Model({
          id,
          type: 'org'
        });

        const view = new ContactListView({
          model,
          api
        });

        Organizations.load(id).done(function(org) {
          const OrgRouter = require('routers/OrganizationRouter');
          OrgRouter.showOrgViewChild(id, org.name, view, 'contacts');
        });
      });
    },
    settings(id) {
      BluePromise.all([
        Organizations.load(id),
        import(
          /* webpackChunkName: "OrgFormView" */
          'views/organizations/OrgFormView'
        )
      ]).spread((org, { default: OrgFormView }) => {
        const view = new OrgFormView({
          model: org
        });

        const OrgRouter = require('routers/OrganizationRouter');
        OrgRouter.showOrgViewChild(id, org.name, view, 'settings');
      });
    },
    archives(id) {
      require.ensure([], function() {
        const ArchivesView = require('views/archives/ArchivesView');

        Organizations.load(id).done(function(org) {
          const view = new ArchivesView({
            model: org
          });

          const OrgRouter = require('routers/OrganizationRouter');
          OrgRouter.showOrgViewChild(id, org.name, view, 'archives');
        });
      });
    },
    archiveSettings(id) {
      require.ensure([], function() {
        const ArchiveSettingsView = require('views/archives/ArchiveSettingsView');
        const OrgArchiveSettingsAPI = require('api/OrgArchiveSettingsAPI');

        Organizations.load(id).done(function(org) {
          const url = Mustache.render('/api/org/{{id}}/archive-path', {
            id
          });

          $.ajax({
            type: 'GET',
            url,
            success(path) {
              const model = new Backbone.Model(path);
              model.set('org', org);

              const view = new ArchiveSettingsView({
                model,
                api: OrgArchiveSettingsAPI
              });

              const OrgRouter = require('routers/OrganizationRouter');
              OrgRouter.showOrgViewChild(
                id,
                org.name,
                view,
                'archives-settings'
              );
            }
          });
        });
      });
    },
    invitations(id) {
      require.ensure([], function() {
        const InvitationsView = require('views/permissions/InvitationsView');
        const tmplPermissions = require('permissions/user/InvitableRolePermissions.tmpl');

        const api = require('api/OrgAPI');

        Organizations.load(id).done(function(org) {
          const view = new InvitationsView({
            model: org,
            api,
            tmplPermissions: tmplPermissions(),
            leaveRoute: OrgRoutes.orgSettings(id)
          });

          const OrgRouter = require('routers/OrganizationRouter');
          OrgRouter.showOrgViewChild(id, org.name, view, 'settings');
        });
      });
    },
    embargoes(id) {
      BluePromise.all([
        Organizations.load(id),
        import('api/OrgAPI'),
        import(
          /* webpackChunkName: "EmbargoSettingsView" */
          'views/embargoes/EmbargoSettingsView'
        )
      ]).spread(function(org, api, { default: EmbargoSettingsView }) {
        const view = new EmbargoSettingsView({
          model: org,
          api
        });

        const OrgRouter = require('routers/OrganizationRouter');
        OrgRouter.showOrgViewChild(id, org.name, view, 'embargoes');
      });
    },
    picSettings(id) {
      log.debug('picSettings id: %s', id);

      require.ensure([], function() {
        require('cropper/cropper.min');

        Organizations.load(id).done(function(org) {
          const OrgProfilePicView = require('views/organizations/OrgProfilePicView');

          const view = new OrgProfilePicView({
            model: org
          });

          const OrgRouter = require('routers/OrganizationRouter');
          OrgRouter.showOrgViewChild(id, org.name, view, 'settings');
        });
      });
    },
    addMembers(id) {
      require.ensure([], function() {
        const MembersSuggestionsView = require('views/permissions/MembersSuggestionsView');
        const tmplPermissions = require('permissions/user/RolePermissions.tmpl');
        const api = require('api/OrgAPI');

        Organizations.load(id).done(function(org) {
          app.m.getRegion('main').show(
            new MembersSuggestionsView({
              model: org,
              api,
              tmplPermissions: tmplPermissions(),
              leaveRoute: OrgRoutes.orgSettings(id)
            })
          );
        });
      });
    },
    organization(id, name) {
      app.selected.org = {
        id,
        slugName: name
      };

      BluePromise.all([
        Organizations.load(id),
        import('views/projects/ProjectsView')
      ]).spread((org, { default: ProjectsView }) => {
        const view = new ProjectsView({
          orgId: id,
          editable: org.isEditable()
        });

        const OrgRouter = require('routers/OrganizationRouter');
        OrgRouter.showOrgViewChild(id, name, view, 'projects');
      });
    },
    organizations() {
      require.ensure([], function() {
        const OrgsView = require('views/organizations/OrgsView');
        app.m.getRegion('main').show(new OrgsView());
      });
    },

    metadata(id, page, section) {
      const showFn = function(metadataView, ob) {
        Organizations.load(id).done(function(org) {
          const OrgRouter = require('routers/OrganizationRouter');
          OrgRouter.showOrgViewChild(id, org.name, metadataView, 'metadata');
        });
      };

      import(
        /* webpackChunkName: "MetadataLoader" */
        'views/metadata/MetadataLoader'
      ).then(({ default: MetadataLoader }) => {
        MetadataLoader(
          showFn,
          OrgRoutes.orgMetadata(id),
          'organization',
          id,
          page,
          section,
          'Organization metadata',
          '#header-nav'
        );
      });
    }
  },
  {
    showOrgViewChild(orgId, name, childView, selected) {
      require.ensure([], function() {
        const OrgView = require('views/organizations/OrgView');

        const { currentView } = app.m.getRegion('main');

        if (currentView instanceof OrgView) {
          OrgView.updateModel(orgId, selected);

          currentView.getRegion('main').show(childView);
        } else {
          const view = new OrgView({
            orgId,
            name,
            selected
          });

          Backbone.Events.listenToOnce(view, 'show', function() {
            view.getRegion('main').show(childView);
          });

          app.m.getRegion('main').show(view);
        }
      }); // require.ensure
    }
  }
); // router
