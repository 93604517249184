var CampaignRoutes = require('routes/CampaignRoutes');
var OrgRoutes = require('routes/OrgRoutes');
var ProjectRoutes = require('routes/ProjectRoutes');

var Routing = require('routers/Routing');
var api = require('api/QuickNavAPI');

var CampaignsAPI = require('api/CampaignsAPI');

var tmplProjects = require('quicknav/projectLinks.tmpl');
var tmplCampaigns = require('quicknav/campaignLinks.tmpl');
var tmplOrgs = require('quicknav/orgLinks.tmpl');

require('profile-pic.styl')

//SidebarView
module.exports = Backbone.Marionette.ItemView.extend({
	el: '#sidebar',
	events: {
		'click a.projects': 'viewProjects',
		'click a.campaigns': 'viewCampaigns',
		'click a.organizations': 'viewOrgs',
		'click ul.recent a': 'viewRecent',
		'click': 'expandSidebar',
		'mouseleave': 'collapseSidebar',
		'click li.campaigns a.toggle': 'toggleCampaigns',
		'click li.campaigns a.collapse-up': 'toggleCampaigns'
	},
	viewProjects: function(event) {
		event.preventDefault();

		if (!this.isExpanded()) {
			return;
		}

		event.stopPropagation();

		this.closeSidebar();

		//reset lastView
		app.lastViews = [];
		app.cache.projects = null;

		this.hideSecondaryViews();

		console.log('SidebarView.viewProjects');

		app.router.navigate(ProjectRoutes.projects(), true);
	},
	viewCampaigns: function(event) {
		event.preventDefault();

		if (!this.isExpanded()) {
			return;
		}

		event.stopPropagation();

		this.closeSidebar();

		//reset lastView
		app.lastViews = [];
		app.cache.projects = null;

		// this.clearBlur();

		console.log('SidebarView.viewCampaigns');

		app.router.navigate(CampaignRoutes.campaigns(), true);
	},
	viewOrgs: function(event) {
		event.preventDefault();

		if (!this.isExpanded()) {
			return;
		}

		event.stopPropagation();

		this.closeSidebar();

		//reset lastView
		app.lastViews = [];
		app.cache.projects = null;

		console.log('SidebarView.viewOrganizations');

		app.router.navigate(OrgRoutes.organizations(), true);
	},
	viewRecent: function(event) {
		// log.debug('viewRecent');

		if (Routing.modified(event)) {return;}

		if (!this.isExpanded()) {
			return;
		}

		event.stopPropagation();

		this.closeSidebar();

		//reset lastView
		app.lastViews = [];
		app.cache.projects = null;

		//hide new-activity to prevent refresh flicker
		//when sidebar is opened again
		var el = $(event.currentTarget);
		el.find('div.new-activity').hide();

		var url = $(event.currentTarget).attr('href');

		app.router.navigate(url.substring(1), true);
	},
	hideSecondaryViews: function() {
		if (app.views.chat && app.views.chat.chatVisible) {
			app.views.chat.toggleChat();
		}

		if (app.views.search) {
			app.views.search.hide();
		}
	}, 
	closeSidebar: function() {
		// log.debug('closeSidebar');

		if (!app.config.sidebarOpenOnClick) {
			this.forceCollapse = true;
			this.collapseSidebar();
		}
	},
	expandSidebar: function(event) {

		if (this.$el.hasClass('expanded')) {
			return;
		}

		// log.debug('expandSidebar');

		event.preventDefault();
		event.stopPropagation();

		this.$el.addClass('expanded textured');

		if (!app.user.loggedIn()) {
			return;
		}

		if (!this.throttledRecent) {
			this.throttledRecent = _.throttle(this.fetchRecent, 5000);
			this.throttledRecent();

		} else {
			this.throttledRecent();
		}
	},
	collapseSidebar: function() {
		var that = this;

		// log.debug('collapseSidebar, forceCollapse: %s', that.forceCollapse);

		if (!that.forceCollapse) {

			if (!that.isExpanded()) {
				return;
			}

			if (that.$el.find('li.campaigns').hasClass('expanded')) {
				// return;
			}

		} else {

			//reset
			that.forceCollapse = false;
		}
		

		// this.clearBlur();

		//reset styles
		this.$el.find('li.projects').show();
		this.$el.find('li.campaigns').show();
		this.$el.find('li.organizations').show();

		//reset expanded lists
		this.$el.find('li').removeClass('expanded');
		this.$el.find('i.flipped').removeClass('flipped');
		this.$el.find('input.search').hide();

		//reset content
		var ulCampaigns = this.$el.find('ul.campaigns');
		ulCampaigns.html(this.recentCampaigns);

		this.$el.find('input.search').val('');

		that.$el.addClass('collapsing');
		that.$el.removeClass('expanded');

		//remove texture bg, delay to sync with css width transition
		_.delay(function() {
			that.$el.addClass('glass').removeClass('textured');
		}, 300);
		
		_.delay(function() {
			that.$el.removeClass('collapsing glass');

		}, 1000);
	},
	isExpanded: function() {
		if (this.$el.hasClass('expanded')) {
			return true;
		} else return false;
	},
	fetchRecent: function() {
		// log.debug('fetchRecent');

		var that = this;

		$.getJSON(api.getList(), function(data) {

			_.each(data.campaigns, function(campaign) {
				campaign.slugName = Routing.slugify(campaign.name);
			})

			_.each(data.orgs, function(org) {
				org.slugName = Routing.slugify(org.name);
			})

			//abort if user has expanded a category already

			if (that.$el.find('li.campaigns.expanded').length) {
				return;
			}

			that.$el.find('ul.projects').html(tmplProjects(data));
			that.$el.find('ul.campaigns').html(tmplCampaigns(data));
			that.$el.find('ul.orgs').html(tmplOrgs(data));

			//let user view addl campaigns if recent list is at max
			if (data.campaigns && data.campaigns.length >=5) {
				that.$el.find('li.campaigns a.toggle:not(.collapse-up)').css('display', 'block')
			}

			//todo: if height exceeds sidebar height then hide elements
		})
	},
	fillCampaigns: function() {
		var that = this;

		if (that.fetchingCampaigns) {
			return;
		}

		that.fetchingCampaigns = true;

		this.getCampaigns('private').done(function( obj ) {

			_.each(obj.campaigns, function(campaign) {
				campaign.slugName = Routing.slugify(campaign.name);
			});

			var ul = that.$el.find('ul.campaigns');

			that.recentCampaigns = ul.html();

			that.allCampaigns = tmplCampaigns(obj);

			ul.html(that.allCampaigns);

			that.fetchingCampaigns = false;
		});
	},
	getCampaigns: function(type) {
		var deferred = $.Deferred();

		$.ajax({
			type: "GET",
			url: CampaignsAPI.getCampaigns(type),
			success: function(data) {
				deferred.resolveWith(this, [data]);
			}
		});

		return deferred.promise();
	},
	toggleCampaigns:function(event) {

		if (!this.isExpanded()) {
			log.debug('toggleCampaigns aborted, not expanded')
			return
		}

		event.preventDefault();
		event.stopPropagation();

		var li = this.$el.find('li.campaigns');
		var input = this.$el.find('li.campaigns input.search');

		if (!li.hasClass('expanded')) {
			li.addClass('expanded');
			// this.bindBlur();

			this.$el.find('li.projects').slideUp();
			this.$el.find('li.organizations').fadeOut();

			input.fadeIn();
			input.focus();

			this.fillCampaigns();

		} else {
			var ul = this.$el.find('ul.campaigns');
			ul.html(this.recentCampaigns);

			li.removeClass('expanded');

			input.fadeOut();

			this.$el.find('li.projects').slideDown();
			this.$el.find('li.organizations').fadeIn();

			// this.clearBlur();
		}

		//flip caret
		var i = li.find('a.toggle i');

		if (!i.hasClass('flipped')) {
			i.addClass('flipped');
		} else {
			i.addClass('flipout');
			i.removeClass('flipped');

			_.delay(function() {
				i.removeClass('flipout')
			}, 600)
		}		
	},
	bindBlur: function() {
		var that = this;

		log.debug('bindBlur')

		$('body').on('click.blursidebar', function(event) {

			var el = $(event.target);

			log.debug('blur click el: %o', el);

			if (el.parents('#sidebar-nav').length) {
				return false;
			}

			log.debug('triggerCollapse');

			that.forceCollapse = true;
			that.collapseSidebar();
		})
	},
	clearBlur: function() {
		log.debug('clearBlur');
		$('#main').off('click.blursidebar');
	},
	bindCampaignSearch: function() {
		var that = this;

		var options = {
		    callback: function (value) {

		    	if (value.length <3) {
		    		if (value.length == 0) {
		    			that.resetSearch();
		    		}

		    		return;
		    	}

		    	that.searchCampaigns(value);
		    },
		    wait: 150,
		    highlight: true,
		    allowSubmit: true,
		    captureLength: 0
		}

		var input = this.$el.find('.campaigns input.search');
		input.typeWatch( options );
	},
	resetSearch: function() {
		log.debug('resetSearch');

		this.$el.find('ul.campaigns').html(this.allCampaigns);
	},
	searchCampaigns: function(query) {
		var that = this;

		log.debug('searchCampaigns: %o', query);

		$.ajax({
			type: "GET",
			url: CampaignsAPI.search(query),
			success: function(campaigns) {

				var data =  {
					campaigns: campaigns
				};

				_.each(data.campaigns, function(campaign) {
					campaign.slugName = Routing.slugify(campaign.name);
				})

				var ul = that.$el.find('ul.campaigns');
				ul.html(tmplCampaigns(data));
			}
		});
	},
	initialize: function() {
		this.bindCampaignSearch();
	}
});