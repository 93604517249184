import * as BluePromise from 'bluebird';

const CampaignRoutes = require('routes/CampaignRoutes');

const Campaigns = require('models/Campaigns');

// CampaignRouter
module.exports = Backbone.Router.extend(
  {
    routes: {
      'campaigns/create': 'create',
      'campaign/:id/:name/metadata(/)(:page)(/)(:section)': 'campaignMetadata',
      'campaign/:id/:name/contacts': 'contacts',
      'campaign/:id/:name/settings': 'settings',
      'campaign/:id/:name/archives': 'archives',
      'campaign/:id/:name/archive-settings': 'archiveSettings',
      'campaign/:id/embargoes': 'embargoes',
      'campaign/:id/:name/notices': 'notices',
      'campaign/:id/:name/invitations': 'invitations',
      'campaign/:id/:name/settings/add-members': 'addMembers',
      'campaign/:id/:name/settings/add-organizations': 'addOrgs',
      'campaign/:id/:name': 'campaign',
      'campaign/:id/:name/about': 'about',
      campaigns: 'campaigns'
    },
    initialize() {
      this.bind('all', this.change);
    },
    change() {
      $('.qtip').qtip('hide');
    },
    create() {
      require.ensure([], function() {
        const CampaignFormView = require('views/campaigns/CampaignFormView');
        app.m.getRegion('main').show(new CampaignFormView());
      });
    },
    contacts(id, name) {
      app.selected.campaign = {
        id,
        slugName: name
      };

      require.ensure([], function() {
        const ContactListView = require('views/contacts/ContactListView');
        const api = require('api/CampaignContactsAPI');

        const model = new Backbone.Model({
          id,
          type: 'campaign'
        });

        const view = new ContactListView({
          model,
          api
        });

        Campaigns.load(id).done(function(campaign) {
          const CampaignRouter = require('routers/CampaignRouter');
          CampaignRouter.showCampaignViewChild(
            id,
            campaign.name,
            view,
            'contacts'
          );
        });
      });
    },
    settings(id) {
      BluePromise.all([
        Campaigns.load(id),
        import(
          /* webpackChunkName: "CampaignFormView" */
          'views/campaigns/CampaignFormView'
        )
      ]).spread((campaign, { default: CampaignFormView }) => {
        const view = new CampaignFormView({ id });

        const CampaignRouter = require('routers/CampaignRouter');
        CampaignRouter.showCampaignViewChild(
          id,
          campaign.name,
          view,
          'settings'
        );
      });
    },
    archives(id) {
      require.ensure([], function() {
        const ArchivesView = require('views/archives/ArchivesView');

        Campaigns.load(id).done(function(campaign) {
          const view = new ArchivesView({
            model: campaign
          });

          const CampaignRouter = require('routers/CampaignRouter');
          CampaignRouter.showCampaignViewChild(
            id,
            campaign.name,
            view,
            'archives'
          );
        });
      });
    },
    archiveSettings(id) {
      require.ensure([], function() {
        const ArchiveSettingsView = require('views/archives/ArchiveSettingsView');
        const CampaignArchiveSettingsAPI = require('api/CampaignArchiveSettingsAPI');

        Campaigns.load(id).done(function(campaign) {
          const url = Mustache.render('/api/campaign/{{id}}/archive-path', {
            id
          });

          $.ajax({
            type: 'GET',
            url,
            success(path) {
              const model = new Backbone.Model(path);
              model.set('campaign', campaign);

              const view = new ArchiveSettingsView({
                model,
                api: CampaignArchiveSettingsAPI
              });

              const CampaignRouter = require('routers/CampaignRouter');
              CampaignRouter.showCampaignViewChild(
                id,
                campaign.name,
                view,
                'archives-settings'
              );
            }
          });
        });
      });
    },
    embargoes(id) {
      BluePromise.all([
        Campaigns.load(id),
        import('api/CampaignAPI'),
        import(
          /* webpackChunkName: "EmbargoSettingsView" */
          'views/embargoes/EmbargoSettingsView'
        )
      ]).spread(function(campaign, api, { default: EmbargoSettingsView }) {
        const view = new EmbargoSettingsView({
          model: campaign,
          api
        });

        const CampaignRouter = require('routers/CampaignRouter');
        CampaignRouter.showCampaignViewChild(
          id,
          campaign.name,
          view,
          'embargoes'
        );
      });
    },
    notices(id) {
      require.ensure([], function() {
        const NoticesView = require('views/campaigns/NoticesView');

        Campaigns.load(id).done(function(campaign) {
          const view = new NoticesView({
            model: campaign
          });

          const CampaignRouter = require('routers/CampaignRouter');
          CampaignRouter.showCampaignViewChild(
            id,
            campaign.name,
            view,
            'notices'
          );
        });
      });
    },
    invitations(id, name) {
      require.ensure([], function() {
        const InvitationsView = require('views/permissions/InvitationsView');
        const tmplPermissions = require('permissions/user/InvitableRolePermissions.tmpl');

        const api = require('api/CampaignAPI');

        Campaigns.load(id).done(function(campaign) {
          const view = new InvitationsView({
            model: campaign,
            api,
            tmplPermissions: tmplPermissions(),
            leaveRoute: CampaignRoutes.campaignSettings(id, name)
          });

          const CampaignRouter = require('routers/CampaignRouter');
          CampaignRouter.showCampaignViewChild(id, campaign.name, view);
        });
      });
    },
    addMembers(id, name) {
      require.ensure([], function() {
        const MembersSuggestionsView = require('views/permissions/MembersSuggestionsView');
        const tmplPermissions = require('permissions/user/RolePermissions.tmpl');
        const api = require('api/CampaignAPI');

        Campaigns.load(id).done(function(campaign) {
          app.m.getRegion('main').show(
            new MembersSuggestionsView({
              model: campaign,
              api,
              tmplPermissions: tmplPermissions(),
              leaveRoute: CampaignRoutes.campaignSettings(id, name)
            })
          );
        });
      });
    },
    addOrgs(id, name) {
      require.ensure([], function() {
        const OrgsSuggestionsView = require('views/permissions/OrgsSuggestionsView');
        const tmplPermissions = require('permissions/ProjectOrgRolePermissions.tmpl');
        const api = require('api/CampaignOrgPermissionsAPI');

        Campaigns.load(id).done(function(campaign) {
          app.m.getRegion('main').show(
            new OrgsSuggestionsView({
              model: campaign,
              api,
              tmplPermissions: tmplPermissions(),
              leaveRoute: CampaignRoutes.campaignSettings(id, name)
            })
          );
        });
      });
    },
    campaign(id, name) {
      app.selected.campaign = {
        id,
        slugName: name
      };

      BluePromise.all([
        Campaigns.load(id),
        import('views/projects/ProjectsView')
      ]).spread((campaign, { default: ProjectsView }) => {
        if (!campaign.isMember()) {
          this.about(id, name);
        } else {
          const view = new ProjectsView({
            campaignId: id,
            editable: campaign.isEditable()
          });

          const CampaignRouter = require('routers/CampaignRouter');
          CampaignRouter.showCampaignViewChild(id, name, view, 'projects');
        }
      });
    },
    about(id, name) {
      app.selected.campaign = {
        id,
        slugName: name
      };

      BluePromise.all([
        Campaigns.load(id),
        import(/* webpackChunkName: "Chart.js" */ 'chart.js'),
        import(/* webpackChunkName: "AboutView" */ 'views/about/AboutView')
      ]).spread(function(campaign, chart, { default: AboutView }) {
        campaign.set('slugName', name);

        const view = new AboutView({
          model: campaign
        });

        const CampaignRouter = require('routers/CampaignRouter');
        CampaignRouter.showCampaignViewChild(id, name, view, 'about');
      });
    },
    campaigns() {
      require.ensure([], function() {
        const CampaignsView = require('views/campaigns/CampaignsView');
        app.m.getRegion('main').show(new CampaignsView());
      });
    },

    campaignMetadata(id, name, page, section) {
      const showFn = function(metadataView, ob) {
        Campaigns.load(id).done(function(campaign) {
          const CampaignRouter = require('routers/CampaignRouter');
          CampaignRouter.showCampaignViewChild(
            id,
            campaign.name,
            metadataView,
            'metadata'
          );
        });
      };

      import(
        /* webpackChunkName: "MetadataLoader" */
        'views/metadata/MetadataLoader'
      ).then(({ default: MetadataLoader }) => {
        MetadataLoader(
          showFn,
          CampaignRoutes.campaignMetadata(id, name),
          'campaign',
          id,
          page,
          section,
          'Campaign metadata'
        );
      });
    }
  },
  {
    getCampaignView(campaignId, name) {
      const CampaignView = require('views/campaigns/CampaignView');

      const { currentView } = app.m.getRegion('main');

      if (currentView instanceof CampaignView) {
        return currentView;
      }
      const view = new CampaignView({
        campaignId,
        name
      });

      return view;
    },
    showCampaignViewChild(campaignId, name, childView, selected) {
      require.ensure([], function() {
        const CampaignView = require('views/campaigns/CampaignView');

        const { currentView } = app.m.getRegion('main');

        if (currentView instanceof CampaignView) {
          CampaignView.updateModel(campaignId, selected);

          currentView.getRegion('main').show(childView);
        } else {
          const view = new CampaignView({
            campaignId,
            name,
            selected
          });

          Backbone.Events.listenToOnce(view, 'show', function() {
            view.getRegion('main').show(childView);
          });

          app.m.getRegion('main').show(view);
        }
      }); // require.ensure
    }
  }
); // router
