var ProjectAPI = require('api/ProjectAPI');

//ProjectCloner
module.exports = {
	clone: function(id) {
		$.ajax({
			type: "POST",
			url: ProjectAPI.clone(id),
			success: function() {
				setTimeout(function() { 
					alert("Your cloned project will be ready soon.");
				}, 1);
		    }
		});
	}
}