//ProjectsEvents
module.exports = {
	filter: 'filter-projects',
	filterText: 'filter-projects-text',
	addLabelFilter: 'add-label-filter-projects',
	getLabelFacets: 'get-label-facets-projects',
	rendered: 'rendered-projects',
	getCurrentPage: 'get-current-page',
	getSearchQuery: 'get-search-query',
	getSelectedLabel: 'get-selected-label',
	getPageModel: 'get-page-model'
}