//OrgRoutes
module.exports = {
	createOrg: function() {
		return 'organizations/create';
	},
	organizations: function(type) {
		var url = 'organizations';

		if (!type) {
			return url;
		} else {
			url += "?type=" + type;
		}

		return url;
	},
	org: function(id, name) {
		return Mustache.render("org/{{id}}/{{name}}", {
			id: id,
			name: name
		});
	},
	orgSettings: function(id) {
		return Mustache.render("org/{{id}}/settings", {
			id: id
		});
	},
	orgInvitations: function(id) {
		return Mustache.render("org/{{id}}/invitations", {
			id: id
		});
	},
	orgPicSettings: function(id) {
		return Mustache.render("org/{{id}}/settings/pic", {
			id: id
		});
	},
	orgSettingsAddMembers: function(id) {
		return Mustache.render("org/{{id}}/settings/add-members", {
			id: id
		});
	},
	orgContacts: function(id, name) {
		return Mustache.render("org/{{id}}/{{name}}/contacts", {
			id: id,
			name: name
		});
	},
	orgMetadata: function(id){
		return Mustache.render("org/{{id}}/metadata", {
			id:id
		})
	},

	orgEmbargoes: function(id){
		return Mustache.render("org/{{id}}/embargoes", {
			id:id
		})
	},
	orgArchiveSettings: function(id, name){
		return Mustache.render("org/{{id}}/{{name}}/archive-settings", {
			id:id,
			name:name
		})
	},
	orgArchives: function(id, name){
		return Mustache.render("org/{{id}}/{{name}}/archives", {
			id:id,
			name:name
		})
	},

	reportsOverview: function(groupId){
		return Mustache.render("org/{{groupId}}/reports/overview", {
			groupId: groupId
		});
	}
}