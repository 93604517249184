//TODO: cross-tab notification to refresh old view when files are cut
// https://html.spec.whatwg.org/multipage/webstorage.html#the-storage-event

module.exports = {
	add: function(ids) {

		var data = {
			folder: app.selected.folder.id,
			files: ids
		};

		localStorage.setItem("clipboard-files", JSON.stringify(data))

		var str = JSON.stringify(ids);
		JSON.parse(str);
	},
	hasFiles: function() {
		var data = JSON.parse(localStorage.getItem("clipboard-files"));

		if (data === null) {
			return false;
		}

		var ids = data.files;

		if (ids.length > 0) {
			return true;
		} else return false;
	},
	getFiles: function() {
		return JSON.parse(localStorage.getItem("clipboard-files"));
	},
	clear: function() {
		localStorage.removeItem("clipboard-files")
	}
};