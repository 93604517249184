var LoginDropdownView = require('views/header/LoginDropdownView');

var MessageEvents = require('events/messages/MessageEvents');
var UserEvents = require('events/user/UserEvents');

var UserAPI = require('api/UserAPI');
var MessageAPI = require('api/MessageAPI');

var Image = require('util/Image');

var Routing = require('routers/Routing');

var routes = require('routes/UserRoutes');

//HeaderView
module.exports = Backbone.Marionette.LayoutView.extend({
	el:'#header',
	views: {},
	regions: {
		messages: '.messages .list',
		search: '.search',
		feedback: '.feedback'
	},
	events: {
		'click a.user': 'viewProfile',
		'click a.profile': 'viewProfile',
		'click a.login': 'showLogin',
		'click a.signup': 'showSignup',
		'click .messages a': 'showMessages',
		'click a.feedback': 'showFeedback'
	},
	onDestroy: function() {
		app.events.off(MessageEvents.newMessage, this.newMessage);
		app.events.off(UserEvents.newProfilePic, this.updateProfilePic);
	},
	newMessage: function(data) {
		var that = this;

		log.debug('newMessage: %o', data);

		var text = 'You have a new message';

		if (data) {
			text = data.message;
		}

		if (data && data.message) {
			var n = new Notification(
				text,
				{
					tag: 'new-message',
					icon: '/public/images/logo/delta_padded.png'
				}
			);

			app.notifications.push(n);

			n.onclick = function() {

				if (data.id) {
					//mark message as read

					$.ajax({
						type: "POST",
						url: MessageAPI.markRead(),
						data: {
							ids: [data.id]
						},
						success: function() {
							log.debug('read message %s', data.id);
							that.$el.find('.messages').removeClass('newMessage');
					    }
					});
				}

				if (data.action) {
					log.debug('navigating to action: %s', data.action);
					app.router.navigate(data.action, true);
				}

				parent.focus();
				window.focus();
				this.close();
			};
			setTimeout(n.close.bind(n), 30000);
		}

		this.$el.find('.messages').addClass('newMessage');
	},
	knock: function() {
		log.debug('knock knock');
		new Audio('/public/lib/knock/knock.mp3').play();
	},
	updateProfilePic: function() {
		// log.debug('HeaderView.updateProfilePic');

		var url = UserAPI.getPicture(app.user.id);
		Image.reload(url);
	},
	showMessages: function(event) {
		if (Routing.modified(event)) {return;}

		this.$el.find('.messages').removeClass('newMessage');

		var that = this;
		require.ensure([], function() {
			var MiniMessagesView = require('views/header/MiniMessagesView');
			that.messages.show(new MiniMessagesView());
		});
	},
	showFeedback: function(event) {
		event.preventDefault();

		const FeedbackView = require('views/feedback/FeedbackView');
		
		const feedbackView = new FeedbackView();
		
		feedbackView.render();
		$(document.body).append(feedbackView.el);

		const ref = feedbackView.$('.feedback-iframe').attr('src') + '?ref=' + window.location.href
		feedbackView.$('.feedback-iframe').attr('src', ref)
	
	},
	showSignup: function(event) {
		event.preventDefault();

		app.router.navigate(app.routes.join(), true);
	},
	showLogin: function(event) {
		event.preventDefault();
		event.stopPropagation();

		var that = this;

		if (!this.views.login) {
			this.views.login = new LoginDropdownView({
				el: that.$el.find('div.login')
			});
		} else {
			this.views.login.initialize({
				el: that.$el.find('div.login')
			});
		}
	},
	viewProfile: function(event) {
		event.preventDefault();

		app.router.navigate(routes.profile(app.user.name), true);
	},
	initialize: function(options) {
		if (options.newMessage) {
			this.newMessage();
		}

		if (app.user.loggedIn()) {
			this.$el.find('a.profile').attr('href', '/' + routes.profile(app.user.name));
		}

		app.events.on(MessageEvents.newMessage, this.newMessage, this);
		app.events.on("knock", this.knock, this);

		app.events.on(UserEvents.newProfilePic, this.updateProfilePic, this);
	}
});