var Routing = require('routers/Routing');


//LoginRouter
module.exports = Backbone.Router.extend({
	routes: {
		'login': 'showLogin',
		'resetPassword': 'resetPassword'
	},
	showLogin: function(name) {
		log.debug('showLogin');

		require.ensure([], function() {
			var LoginView = require('views/login/LoginView');

			log.debug('show LoginView');

			app.m.getRegion('main').show(new LoginView());

		}); //require.ensure
	},
	resetPassword: function(){
		log.debug('show Reset Password');
		require.ensure([], function() {
			require("bootstrap/bootstrap.min.css");
			var ResetPasswordView = require('views/login/ResetPasswordView');

			app.m.getRegion('main').show(new ResetPasswordView());

		}); //require.ensure
	}
}); //router 