var Folder = require('models/Folder');

module.exports = Backbone.Collection.extend({
	model: Folder,
	forEncodedName: function(name) {
		return (this.find(function(data) {
			return data.getEncodedName() == name;
		}));
	},
}, {
	 fromFile: function(id) {
		var that = this;
		var deferred = $.Deferred();

		var url = Mustache.render("/api/folder/for/file/{{id}}", {
			id: id
		});

		$.ajax({
			url: url,
			dataType: "json",
			success: function(json) {
				var folder = new Folder(json);

				deferred.resolveWith(this, [folder]);
			}
		});

		return deferred.promise();
	}
});