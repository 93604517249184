import 'qtip/qtip-user.styl';

const User = require('models/User');

const api = require('api/UserAPI');

const tmplProfileTooltip = require('user/ProfileTooltip.tmpl');
const tmplProfileSmallTooltip = require('user/ProfileSmallTooltip.tmpl');

module.exports = {
  showUserTooltip(el, id) {
    const that = this;

    el.qtip({
      content: {
        text(api) {
          $('.qtip.ui-tooltip').qtip('hide');

          let user = app.models.users.get(id);

          if (user == null || !user.has('firstName')) {
            user = new User(that.getUserInfo(id));

            app.models.users.remove(id);
            app.models.users.add(user);
          }

          const json = user.getProfileJSON();

          return tmplProfileTooltip(json);
        }
      },
      show: {
        solo: true,
        delay: 150,
        event: 'mouseover'
      },
      hide: {
        delay: 200,
        fixed: true
      },
      events: {
        hidden(event, api) {
          // Destroy it immediately
          api.destroy(true);
        }
      },
      position: {
        my: 'right center',
        at: 'left:center'
      },
      style: {
        classes: 'qtip-shadow qtip-light qtip-rounded qtip-user'
      }
    });
  },
  bindSmallUserTooltip(el, options) {
    const that = this;

    const id = el.data('id');

    // set defaults
    if (!options) {
      // eslint-disable-next-line no-param-reassign
      options = {
        position: {
          my: 'top center',
          at: 'bottom:center'
        }
      };
    }

    el.qtip({
      content: {
        text(api) {
          $('.qtip.ui-tooltip').qtip('hide');

          let user = app.models.users.get(id);

          if (user == null || !user.has('firstName')) {
            user = new User(that.getUserInfo(id));

            app.models.users.remove(id);
            app.models.users.add(user);
          }

          const json = user.getProfileJSON();

          return tmplProfileSmallTooltip(json);
        }
      },
      show: {
        solo: true,
        event: 'mouseover',
        delay: 300
      },
      hide: {
        delay: 200,
        fixed: true,
        event: 'mouseleave'
      },
      position: options.position,
      style: {
        classes: 'qtip-shadow qtip-rounded qtip-user'
      },
      events: {
        render(event, api) {
          that.bindClick(this);
        }
      }
    });
  },
  bindClick(el) {
    // use Backbone router instead of default click behavior

    $('.qtip-user').on('click.qtip-user', 'a.user', function(event) {
      event.preventDefault();
      event.stopPropagation();

      const href = $(event.currentTarget).attr('href');
      app.router.navigate(href.substring(1), true);
    });
  },
  getUserInfo(id) {
    let info = null;

    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: api.getInfo(id),
      async: false,
      success(json) {
        info = json;
      }
    });

    return info;
  }
};
// module.exports = UserTooltips;
