import 'main.styl';
import 'backgrounds.styl';

const Users = require('models/Users');
const Projects = require('models/Projects');
const Models = require('models/Models');

const HeaderView = require('views/header/HeaderView');
const SidebarView = require('views/projects/SidebarView');

const SearchView = require('views/search/SearchView');

const tmplProfileHeaderUser = require('user/ProfileHeaderUser.tmpl');

const ErrorRouter = require('routers/ErrorRouter');
const ProjectRouter = require('routers/ProjectRouter');

const FileRouter = require('routers/FileRouter');
const UserRouter = require('routers/UserRouter');
const ArchiveRouter = require('routers/ArchiveRouter');
const CampaignRouter = require('routers/CampaignRouter');
const OrganizationRouter = require('routers/OrganizationRouter');
const LoginRouter = require('routers/LoginRouter');
const ReportsRouter = require('routers/ReportsRouter')
const Background = require('util/Background');
const PageEvents = require('events/PageEvents');
const SocketIOListener = require('events/SocketIOListener');

const url = require('routers/URL');

(function($) {
  Backbone.emulateJSON = true;
  const defaultRender = Backbone.Marionette.Renderer.render;
  Backbone.Marionette.Renderer.render = function(template, data) {
    if (_.isFunction(template)) {
      return defaultRender(template, data);
    }
    return Mustache.render(template, data);
  };

  app = {
    title: 'Research Workspace',
    login: { user: {} },
    selected: {
      group: {},
      project: {},
      metadata: {}
    },
    clipboard: require('mixins/ClipBoard'),
    notifications: [],
    user: {
      loggedIn() {
        if (typeof app.user.id !== 'undefined') {
          return true;
        }
        return false;
      },
      isMacLike() {
        return !!navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i);
      }
    },
    group: {},
    models: Models,
    views: {},
    lastViews: [],
    // events: _.clone(Backbone.Events),
    events: Backbone.Radio.channel('global'),
    routes: {
      join() {
        return 'join';
      },
      searchUser(id) {
        return Mustache.to_html('group/{{id}}/user/{{userId}}/search', {
          id: app.group.id,
          userId: id
        });
      },
      viewUsers() {
        return Mustache.to_html('group/{{id}}/users', {
          id: app.group.id
        });
      },
      search() {
        return Mustache.to_html('group/{{id}}/search', {
          id: app.group.id
        });
      },
      file(id, name) {
        return Mustache.to_html('files/{{id}}/{{name}}', {
          id,
          name
        });
      },
      newFile(id, folderId) {
        return Mustache.to_html('file/in/project/{{id}}/folder/{{folderId}}', {
          id,
          folderId
        });
      },
      newNotebookFile(id, folderId) {
        return Mustache.to_html(
          'file/nb/in/project/{{id}}/folder/{{folderId}}',
          {
            id,
            folderId
          }
        );
      },
      newTextFile(id, folderId) {
        return Mustache.to_html(
          'file/txt/in/project/{{id}}/folder/{{folderId}}',
          {
            id,
            folderId
          }
        );
      },
      editor(id, name) {
        return Mustache.to_html('file/{{id}}/{{name}}', {
          id,
          name
        });
      },
      profile(id) {
        return Mustache.to_html('user/{{id}}', {
          id
        });
      },
      groupSettings(id) {
        return Mustache.to_html('group/{{groupId}}/settings', {
          groupId: app.selected.group.id,
          id
        });
      },
      notices(id) {
        return Mustache.to_html('group/{{groupId}}/notices', {
          groupId: app.selected.group.id
        });
      }
    },
    routers: {},
    widgets: {
      table: null,
      projects: {},
      file: {
        url: '/files/{{id}}/{{fileName}}',
        getUrl(id, fileName) {
          return Mustache.to_html(this.url, {
            id,
            fileName
          });
        }
      }
    },
    cache: {
      projectsCached() {
        const currentRoute = Backbone.history.getFragment();
        if (app.cache.projects && app.cache.projects.route === currentRoute) {
          return true;
        }
        return false;
      }
    },
    loadConsole() {
      if (app.user.isAdmin) {
        require.ensure([], function() {
          require('terminal/jquery.terminal-min.js');
          require('terminal/jquery.terminal.css');

          const ConsoleView = require('views/admin/ConsoleView');
          app.views.console = new ConsoleView();
        });
      }
    },
    setProfileHeader() {
      const html = tmplProfileHeaderUser(app.user);
      $('#profile a.user').html(html);
    },
    init() {
      log.enableAll();
      log.info('showing all log messages');

      app.host = window.location.hostname;

      this.user.id = user.id;
      this.user.firstName = user.firstName;
      this.user.lastName = user.lastName;
      this.user.isAdmin = user.isAdmin;
      this.user.name = user.name;
      this.user.initials = user.initials;
      this.user.color = user.color;
      this.user.startRoute = user.startRoute;

      if (app.user.loggedIn()) {
        Background.setRandom();

        this.setProfileHeader();
      } else {
        Background.removeBackground();
      }

      this.config = config;

      // Create an Application
      const m = new Marionette.Application();

      // Add a region
      m.addRegions({
        header: '#header-nav',
        sidebar: '#sidebar-nav',
        main: '#main',
        dialog: '.main-dialog'
      });

      const header = new HeaderView({
        newMessage
      });

      m.getRegion('header').attachView(header);

      if (app.user.loggedIn()) {
        header.getRegion('search').show(new SearchView());

        $('#sidebar').show();
        m.getRegion('sidebar').attachView(new SidebarView());
      }

      app.events.on(PageEvents.setTitle, function(name) {
        document.title = `${name} | ${app.title}`;
      });

      app.events.on(PageEvents.setMetaDesc, function(desc) {
        const el = $('meta[name=description]');

        // preserve original description
        el.data('orig-desc', el.attr('content'));

        // set new description
        el.attr('content', desc);
      });

      app.events.on(PageEvents.resetMetaDesc, function(desc) {
        const el = $('meta[name=description]');

        el.attr('content', el.data('orig-desc'));
      });

      app.events.on(PageEvents.setLastParentView, function(lastView) {
        // log.debug('setLastParentView: %o', lastView)

        const result = _.find(app.lastViews, function(item) {
          return item.name === lastView.name;
        });

        // don't insert duplicate nav targets (metadata editor)
        if (!result) {
          app.lastViews.push(lastView);
        } else {
          // log.debug('found duplicate nav target: %s', lastView.name);
        }
      });

      app.events.on('reload', function() {
        window.location.reload();
      });

      m.start();

      this.m = m;

      this.branding = branding;
      this.config = config;

      this.models.users = new Users();
      this.models.projects = new Projects();

      this.routers.user = new UserRouter();
      this.routers.project = new ProjectRouter();
      this.routers.campaign = new CampaignRouter();
      this.routers.organization = new OrganizationRouter();
      this.routers.file = new FileRouter();
      this.routers.archive = new ArchiveRouter();
      this.routers.error = new ErrorRouter();

      this.routers.login = new LoginRouter();
      this.routers.reports = new ReportsRouter();
      
      this.loadConsole();

      $.ajaxSetup({
        statusCode: {
          401() {
            if (!app.user.id) {
              app.initialLocation = window.location.pathname;

              app.router.navigate('login', { trigger: true, replace: true });
            } else {
              app.lastRequest = window.location.pathname;

              // not authorized
              app.router.navigate('error/401', {
                trigger: true,
                replace: true
              });
            }
          }
        }
      });

      const history = [];
      Backbone.history.on('route', function(name, args) {
        document.title = app.title;

        history.push({
          name,
          args,
          fragment: Backbone.history.fragment
        });
      });

      this.history = history;

      Backbone.history.start({
        pushState: Modernizr.history
      });

      app.router = app.routers.project;

      const email = url.getParam('email');

      if (app.user.id) {
        SocketIOListener.initialize();

        // ask for notification permission on show
        if ('Notification' in window && Notification.permission === 'default') {
          Notification.requestPermission();
        }

        if (initView === 'login') {
          app.router.navigate(app.user.startRoute, true);
        }
      } else if (!email) {
        if (encryptedEmail) {
          if (encryptedEmail !== 'expired') {
            app.router.navigate('resetPassword', true);
          } else {
            app.router.navigate('login', true);
          }
        }
      }
    }
  };

  // TODO: something else
  require('./FileGlobals');
  $('a#rw-logo img').css('opacity', 1);

  app.init();
})(jQuery);