
var api = require('api/SearchAPI');

var Events = require('events/search/SearchEvents');

var SearchResultsView = require('views/search/SearchResultsView');

var tmpl = require('search/SearchView.tmpl');

require('search/search.styl')

//SearchView
module.exports = Backbone.Marionette.LayoutView.extend({
	template: tmpl,
	regions: {
		searchResults: 'div.searchResults'
	},
	onDestroy: function() {
		app.events.off(Events.clearSearch, this.clearSearch);

		Mousetrap.unbind('ctrl+shift+f');
	},
	bindAutoComplete: function() {
		var that = this;
		var options = {
			serviceUrl: api.search(),
			minChars: 3,
			width: 450,
			deferRequestBy: 200,
			noCache: true,
			appendTo: that.el,
			/* lookup:[],*/
			transformResult: function(response) {
				that.setResults(response);

				// log.debug(response);

				return "";
			},
			onSearchStart: function(query) {
				// that.setResults();
				//show search spinner

			},
			onSelect: function(value, id) {
				console.log('selected ' + value + " " + id);

			} //onSelect	  
		};

		var input = that.$el.find('input.search');
		that.autocomplete = input.autocomplete2(options);
		input.removeAttr('autocomplete');

		// log.debug('SearchView - bindAutocomplete to: %o', that.$el);

		input.keyup(function(e) {
			if (e.keyCode == 8 || e.keyCode == 13) {

				if ($(this).val().length == 0) {
					that.searchResults.empty();
				}
			} 

			//esc
			if (e.keyCode == 27) {
				that.searchResults.empty();
			}
		});

		input.focus(function(e) {
			var val = input.val();

			if (val.length == 0) {
				return;
			}

			$.ajax({
				type: "GET",
				url: api.search(),
				data: {
					query: input.val()
				},
				success: function(response) {
					that.setResults(response);
				}
			});
		});

	},
	setResults: function(response) {
		// log.debug('search files: %o', response.results.files);

		this.searchResults.show(new SearchResultsView({
			results: response.results
		}))

	},
	clearSearch: function() {
		this.$el.find('input.search').val('');
	},
	bindShortcuts: function() {
		var that = this;

		Mousetrap.stopCallback = function(e, element, combo) {
			return false;
		};

		Mousetrap.bind('ctrl+shift+f', function(e) {
		    that.$el.find('input.search').focus();
		    return false;
		});
	},
	onShow: function() {
		this.bindAutoComplete();
		this.bindShortcuts();
	},
	initialize: function() {
		app.events.on(Events.clearSearch, this.clearSearch, this);
	}

}); //SearchView