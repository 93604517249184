//ProjectAPI
module.exports = {
	projectLabels: function(id) {
		return Mustache.render("/api/project/{{id}}/labels", {
			id: id
		});
	},
	getUserProjectLabels: function(id) {
		return Mustache.render("/api/user/{{id}}/project-labels", {
			id: id
		});
	},
	getCampaignProjectLabels: function(id) {
		return Mustache.render("/api/campaign/{{id}}/project-labels", {
			id: id
		});
	},
	getOrgProjectLabels: function(id) {
		return Mustache.render("/api/org/{{id}}/project-labels", {
			id: id
		});
	},
	getCurrentArchive: function(id) {
		return Mustache.render("/api/project/{{id}}/archive", {
			id: id
		});
	},
	archiveFiles: function(id) {
		return Mustache.render("/api/project/{{id}}/archive/files", {
			id: id
		});
	},
	archiveFolder: function(id, folderId) {
		return Mustache.render("/api/project/{{id}}/archive/folder/{{folderId}}", {
			id: id,
			folderId: folderId
		});
	},
	trash: function(id) {
		return Mustache.render("/api/project/{{id}}/trash", {
			id: id
		});
	},
	dois: function(id) {
		return Mustache.render("/api/project/{{id}}/dois", {
			id: id
		});
	},
	clone: function(id) {
		return Mustache.render("/api/project/{{id}}/clone", {
			id: id
		});
	},
	roleExplanation: function(id) {
		return Mustache.render("/api/project/{{id}}/role/explain", {
			id: id
		});	
	},
	isFolderDownloadable: function(id) {
		return Mustache.render("/api/folder/{{id}}/is-downloadable", {
			id: id
		});	
	}
}