import * as BluePromise from 'bluebird';

const Projects = require('models/Projects');
const Folders = require('models/Folders');

const ProjectRoutes = require('routes/ProjectRoutes');

const PageEvents = require('events/PageEvents');

const ProjectView = require('views/projects/ProjectView');
const ProjectTitleView = require('views/projects/ProjectTitleView');

module.exports = Backbone.Router.extend({
  routes: {
    'projects/create': 'create',

    'project/:id/folder/:folderId': 'viewFolder',
    'project/:id/folder/:folderId/:name': 'viewFolder',
    'project/:id/files(/)': 'viewFiles',
    'project/:id/metadata(/)(:page)(/)(:section)': 'viewProjectMetadata',
    'project/:id/activity': 'viewActivity',

    'project/:id/archives': 'archives',
    'project/:id/archive-request/:requestId': 'archiveRequest',
    'project/:id/archives/add-external/:folderId': 'addExternalArchive',
    'project/:id/archives/external/:archiveId': 'editExternalArchive',

    'project/:id/assets': 'assets',
    'project/:id/files/:fileId/history': 'viewHistory',
    'project/:id/files/:fileId/metadata(/)(:page)(/)(:section)':
      'viewFileMetadata',
    'project/:id/folders/:folderId/metadata(/)(:page)(/)(:section)':
      'viewFolderMetadata',
    'project/:id/trash': 'viewTrash',

    'campaign/:id/:name/project/create': 'campaignCreateProject',
    'org/:id/:name/project/create': 'orgCreateProject',
    'project/:id/settings': 'settings',
    'project/:id/invitations': 'invitations',

    'project/:id/archive/folder/:folderId': 'archiveFolder',

    'campaign/:campaignId/:name/project/:id/settings':
      'campaignProjectSettings',
    'org/:orgId/:name/project/:id/settings': 'orgProjectSettings',

    'project/:id/settings/add-members': 'addMembers',
    'campaign/:campaignId/:name/project/:id/settings/add-members':
      'campaignAddMembers',

    'project/:id/settings/add-campaigns': 'addCampaigns',
    'campaign/:campaignId/:name/project/:id/settings/add-campaigns':
      'campaignAddCampaigns',

    'project/:id/settings/add-organizations': 'addOrgs',
    'campaign/:campaignId/:name/project/:id/settings/add-organizations':
      'campaignAddOrgs',
    projects: 'projects'
  },
  initialize() {
    this.bind('all', this.change);
  },
  change() {
    $('.qtip').qtip('hide');
  },
  create() {
    require.ensure([], function() {
      const ProjectFormView = require('views/projects/ProjectFormView');
      app.m.getRegion('main').show(new ProjectFormView());
    }); // require.ensure
  },
  viewFiles(id) {
    log.info('viewFiles');

    this.setFilesLastView();

    BluePromise.all([
      Projects.load(id),
      import(
        /* webpackChunkName: "FilesView" */
        'views/files/FilesView'
      )
    ]).spread((project, { default: FilesView }) => {
      const region = app.m.getRegion('main');
      const projectView = this.getProjectView(region, project, 'files');

      app.views.files = new FilesView();
      projectView.main.show(app.views.files);
    });
  },
  viewFolder(id, folderId) {
    const that = this;
    log.info('FileRouter.viewFolder %s %s', id, folderId);

    this.setFilesLastView();

    const oldProjectId = app.selected.project.id;
    const oldFolderId = app.selected.folder.id;

    Projects.load(id).done(function(project) {
      app.events.trigger(PageEvents.setTitle, project.get('name'));

      const region = app.m.getRegion('main');

      (async () => {
        const { default: FilesView } = await import(
          /* webpackChunkName: "FilesView" */ 'views/files/FilesView'
        );

        // if already looking at folder in same project
        // eslint-disable-next-line eqeqeq
        if (region.currentView instanceof ProjectView && id == oldProjectId) {
          // log.debug('use old ProjectView');
          const projectView = region.currentView;

          const { currentView } = projectView.main;

          // eslint-disable-next-line eqeqeq
          if (currentView instanceof FilesView && oldFolderId == folderId) {
            currentView.refresh();
          } else {
            // if not already on FilesView
            log.debug('viewFolder - new FilesView');

            app.views.files = new FilesView({
              folderId
            });

            projectView.main.show(app.views.files);
          }
        } else {
          // new projectview
          // log.debug('new ProjectView');
          const projectView = that.createProjectView(region, project, 'files');

          app.views.files = new FilesView({
            folderId
          });
          projectView.main.show(app.views.files);
        }
      })(); // async
    }); // selected project lookup
  },
  setFilesLastView() {
    // prevents duplicate files lastViews
    // when browser back button navigation is used

    app.lastViews = _.filter(app.lastViews, function(item) {
      return item.name !== 'files';
    });
  },
  // eslint-disable-next-line camelcase
  viewFileMetadata(project_id, file_id, page, section) {
    Folders.fromFile(file_id).done(function(folder) {
      app.selected.folder = folder;

      const folderRoute = ProjectRoutes.folder();
      app.events.trigger(PageEvents.setLastParentView, {
        name: 'files',
        route: folderRoute
      });
    });

    const showFn = function(metadataView, ob) {
      // file view header is dependant on project loading
      Projects.load(project_id).done(function(project) {
        require.ensure([], function() {
          // override FileEditorView so can include custom header
          const FileEditorView = require('views/editor/FileEditorView');
          const FileEditorHeaderView = require('views/editor/FileEditorHeaderView');
          // custom header view for custom back fn
          const FileMetadataheaderView = FileEditorHeaderView.extend({
            back() {
              if (app.selected.folder.id) {
                this.gotoFolder(
                  this.model.get('projectId'),
                  app.selected.folder.id
                );
              } else {
                app.router.navigate(
                  ProjectRoutes.files(
                    this.model.get('projectId'),
                    this.model.get('fileId')
                  ),
                  true
                );
              }

              return false;
            }
          });
          const FileMetadataView = FileEditorView.extend({
            onShow() {
              const fvh = new FileMetadataheaderView(this.options);
              this.header.show(fvh);
              fvh.ui.fullscreen.css('visibility', 'hidden');
              fvh.ui.download.css('visibility', 'hidden');
            }
          });

          const fv = new FileMetadataView({
            projectId: project_id,
            fileId: file_id,
            name: ob.title
          });
          app.m.getRegion('main').show(fv);

          fv.main.show(metadataView);
        }); // require.ensure
      });
    };

    import(
      /* webpackChunkName: "MetadataLoader" */
      'views/metadata/MetadataLoader'
    ).then(({ default: MetadataLoader }) => {
      MetadataLoader(
        showFn,
        ProjectRoutes.fileMetadata(project_id, file_id),
        'file',
        file_id,
        page,
        section,
        'File metadata',
        'nullselector'
      );
    });
  },
  // eslint-disable-next-line camelcase
  viewFolderMetadata(project_id, folder_id, page, section) {
    const folderRoute = ProjectRoutes.specificFolder(project_id, folder_id);
    app.events.trigger(PageEvents.setLastParentView, {
      name: 'files',
      route: folderRoute
    });

    const that = this;
    const showFn = function(metadataView, ob) {
      Projects.load(project_id).done(function(project) {
        app.events.trigger(PageEvents.setTitle, project.get('name'));

        const region = app.m.getRegion('main');
        const projectView = that.getProjectView(region, project);
        projectView.main.show(metadataView);
      }); // selected project lookup
    };

    import(
      /* webpackChunkName: "MetadataLoader" */
      'views/metadata/MetadataLoader'
    ).then(({ default: MetadataLoader }) => {
      MetadataLoader(
        showFn,
        ProjectRoutes.folderMetadata(project_id, folder_id),
        'folder',
        folder_id,
        page,
        section,
        'Folder metadata'
      );
    });
  },
  // eslint-disable-next-line camelcase
  viewProjectMetadata(project_id, page, section) {
    log.info('FileRouter viewProjectMetadata: %s %s', project_id);

    let filesRoute = ProjectRoutes.files(project_id);
    if (app.selected.folder && app.selected.folder.id) {
      filesRoute = ProjectRoutes.folder();
    }

    app.events.trigger(PageEvents.setLastParentView, {
      name: 'files',
      route: filesRoute
    });

    const that = this;
    const showFn = function(metadataView) {
      Projects.load(project_id).done(function(project) {
        app.events.trigger(PageEvents.setTitle, project.get('name'));

        const region = app.m.getRegion('main');
        const projectView = that.getProjectView(region, project, 'metadata');

        projectView.main.show(metadataView);
      });
    };

    import(
      /* webpackChunkName: "MetadataLoader" */
      'views/metadata/MetadataLoader'
    ).then(({ default: MetadataLoader }) => {
      MetadataLoader(
        showFn,
        ProjectRoutes.metadata(project_id),
        'project',
        project_id,
        page,
        section,
        'Project metadata',
        '.rw-header'
      );
    });
  },
  viewActivity() {
    const that = this;
    Projects.load(id).done(function(project) {
      const region = app.m.getRegion('main');
      const projectView = that.getProjectView(region, project);
      projectView.main.show(new ProjectActivityView());
    });
  },
  archives(id) {
    const that = this;

    require.ensure([], function() {
      const ArchivesView = require('views/archives/ArchivesView');

      Projects.load(id).done(function(project) {
        app.events.trigger(PageEvents.setTitle, project.get('name'));

        const region = app.m.getRegion('main');
        const projectView = that.getProjectView(region, project, 'archives');

        projectView.main.show(
          new ArchivesView({
            model: project
          })
        );
      });
    });
  },
  archiveRequest(id, requestId) {
    const that = this;

    require.ensure([], function() {
      const ArchiveAPI = require('api/ArchiveAPI');

      log.debug('archiverequest view %s', requestId);

      const ArchiveRequestView = require('views/archives/ArchiveRequestView');

      Projects.load(id).done(function(project) {
        app.events.trigger(PageEvents.setTitle, project.get('name'));

        const region = app.m.getRegion('main');
        const projectView = that.getProjectView(region, project, 'archives');

        const url = ArchiveAPI.archiveRequest(requestId);

        $.get(url).done(function(data) {
          data.project.url = `/${ProjectRoutes.files(data.project.id)}`;
          data.folder.url = `/${ProjectRoutes.specificFolder(
            data.project.id,
            data.folder.id
          )}`;

          const model = new Backbone.Model(data);

          projectView.main.show(
            new ArchiveRequestView({
              model
            })
          );
        });
      });
    });
  },
  addExternalArchive(id, folderId) {
    const that = this;

    require.ensure([], function() {
      const ExternalArchiveView = require('views/archives/ExternalArchiveView');

      Projects.load(id).done(function(project) {
        app.events.trigger(PageEvents.setTitle, project.get('name'));

        const region = app.m.getRegion('main');
        const projectView = that.getProjectView(region, project, 'archives');

        projectView.main.show(
          new ExternalArchiveView({
            model: project,
            folderId
          })
        );
      });
    });
  },
  editExternalArchive(id, archiveId) {
    const that = this;

    require.ensure([], function() {
      const ExternalArchiveView = require('views/archives/ExternalArchiveView');

      Projects.load(id).done(function(project) {
        app.events.trigger(PageEvents.setTitle, project.get('name'));

        const region = app.m.getRegion('main');
        const projectView = that.getProjectView(region, project, 'archives');

        projectView.main.show(
          new ExternalArchiveView({
            model: project,
            archiveId
          })
        );
      });
    });
  },
  archiveFolder(id, folderId) {
    const that = this;

    require.ensure([], function() {
      const ArchiveWizardView = require('views/archives/ArchiveWizardView');

      Projects.load(id).done(function(project) {
        app.events.trigger(PageEvents.setTitle, project.get('name'));

        const region = app.m.getRegion('main');
        const projectView = that.getProjectView(region, project, 'archives');

        log.debug('refresh title subnav');

        // refresh title subnav
        projectView.header.show(
          new ProjectTitleView({
            model: project,
            selected: 'archives'
          })
        );

        /*

        projectView.main.show(new ArchivesView( {
          model: project,
          folderId: folderId
        }));
        */

        // show archivewizard instead

        projectView.main.show(
          new ArchiveWizardView({
            model: project,
            folderId
          })
        );
      });
    });
  },

  archiveFolder2(id, folderId) {
    log.debug('ProjectRouter.archiveFolder: %s %s', id, folderId);

    /*
    require.ensure([], function() {
      var ArchiveFolderView = require('views/archives/ArchiveFolderView');

      Projects.load(projectId).done(function( project ) {
        app.m.getRegion('main').show(new ArchiveFolderView({
          model: project,
          folderId: folderId
        }));
      });
    });
*/

    require.ensure([], function() {
      const ArchiveFolderView = require('views/archives/ArchiveFolderView');

      Projects.load(id).done(function(project) {
        app.events.trigger(PageEvents.setTitle, project.get('name'));

        const region = app.m.getRegion('main');

        const projectView = new ProjectView({
          model: project
        });
        region.show(projectView);

        projectView.header.show(
          new ProjectTitleView({
            model: project
          })
        );

        projectView.main.show(
          new ArchiveFolderView({
            model: project
          })
        );
      });
    });
  },

  assets(id) {
    const that = this;

    require.ensure([], function() {
      const AssetsView = require('views/assets/AssetsView');

      Projects.load(id).done(function(project) {
        app.events.trigger(PageEvents.setTitle, project.get('name'));

        const region = app.m.getRegion('main');
        const projectView = that.getProjectView(region, project, 'assets');

        projectView.main.show(
          new AssetsView({
            model: project
          })
        );
      });
    });
  },

  viewTrash(id) {
    const that = this;

    require.ensure([], function() {
      const TrashView = require('views/files/TrashView');

      Projects.load(id).done(function(project) {
        const region = app.m.getRegion('main');
        const projectView = that.getProjectView(region, project);
        projectView.main.show(
          new TrashView({
            model: project
          })
        );
      });
    }); // require.ensure
  },

  campaignCreateProject(id, name) {
    require.ensure([], function() {
      const routes = require('routes/CampaignRoutes');
      const ProjectFormView = require('views/projects/ProjectFormView');

      const view = new ProjectFormView({
        campaignId: id,
        returnRoute: routes.campaign(id, name)
      });

      const CampaignRouter = require('routers/CampaignRouter');
      CampaignRouter.showCampaignViewChild(id, name, view);
    });
  },
  orgCreateProject(id, name) {
    require.ensure([], function() {
      const ProjectFormView = require('views/projects/ProjectFormView');
      const routes = require('routes/OrgRoutes');

      const view = new ProjectFormView({
        orgId: id,
        returnRoute: routes.org(id, name)
      });

      const OrgRouter = require('routers/OrganizationRouter');
      OrgRouter.showOrgViewChild(id, name, view);
    });
  },
  settings(id) {
    BluePromise.all([
      Projects.load(id),
      import(
        /* webpackChunkName: "ProjectFormView" */
        'views/projects/ProjectFormView'
      )
    ]).spread((project, { default: ProjectFormView }) => {
      app.events.trigger(PageEvents.setTitle, project.get('name'));

      const region = app.m.getRegion('main');
      const projectView = this.getProjectView(region, project, 'settings');

      projectView.main.show(
        new ProjectFormView({
          id,
          returnRoute: ProjectRoutes.files(id)
        })
      );
    });
  },
  archive(id) {
    require.ensure([], function() {
      const ProjectAPI = require('api/ProjectAPI');
      const ArchiveView = require('views/projects/ArchiveView');

      Projects.load(id).done(function(project) {
        app.events.trigger(PageEvents.setTitle, project.get('name'));

        const region = app.m.getRegion('main');

        const projectView = new ProjectView({
          model: project
        });
        region.show(projectView);

        projectView.header.show(
          new ProjectTitleView({
            model: project
          })
        );

        $.getJSON(ProjectAPI.getCurrentArchive(id), function(data) {
          log.debug('getARchive: %o', data);

          const archive = new Backbone.Model(data);

          projectView.main.show(
            new ArchiveView({
              model: archive
            })
          );
        });
      });
    });
  },
  invitations(id) {
    require.ensure([], function() {
      const InvitationsView = require('views/permissions/InvitationsView');

      const api = require('api/ProjectInvitationsAPI');
      Projects.load(id).done(function(project) {
        app.m.getRegion('main').show(
          new InvitationsView({
            model: project,
            api,
            returnRoute: ProjectRoutes.projects()
          })
        );
      });
    });
  },
  campaignProjectSettings(campaignId, name, id) {
    require.ensure([], function() {
      const ProjectFormView = require('views/projects/ProjectFormView');
      const routes = require('routes/CampaignRoutes');

      const view = new ProjectFormView({
        id,
        campaignId,
        returnRoute: routes.campaign(campaignId, name)
      });

      const CampaignRouter = require('routers/CampaignRouter');
      CampaignRouter.showCampaignViewChild(campaignId, name, view);
    });
  },
  orgProjectSettings(orgId, name, id) {
    require.ensure([], function() {
      const ProjectFormView = require('views/projects/ProjectFormView');
      const routes = require('routes/OrgRoutes');

      const view = new ProjectFormView({
        id,
        orgId,
        returnRoute: routes.org(orgId, name)
      });

      const OrgRouter = require('routers/OrganizationRouter');
      OrgRouter.showOrgViewChild(orgId, name, view);
    });
  },
  addMembers(id) {
    log.debug('addMembers');

    require.ensure([], function() {
      const MembersSuggestionsView = require('views/permissions/MembersSuggestionsView');
      const tmplProjectPermissions = require('permissions/user/ProjectRolePermissions.tmpl');
      const api = require('api/ProjectMemberPermissionsAPI');

      Projects.load(id).done(function(project) {
        app.m.getRegion('main').show(
          new MembersSuggestionsView({
            model: project,
            api,
            tmplPermissions: tmplProjectPermissions(),
            leaveRoute: ProjectRoutes.projectSettings(id)
          })
        );
      });
    });
  },
  campaignAddMembers(campaignId, name, id) {
    const that = this;

    require.ensure([], function() {
      const CampaignView = require('views/campaigns/CampaignView');

      const MembersSuggestionsView = require('views/permissions/MembersSuggestionsView');
      const tmplProjectPermissions = require('permissions/user/ProjectRolePermissions.tmpl');
      const api = require('api/ProjectMemberPermissionsAPI');

      const CampaignRouter = require('routers/CampaignRouter');
      const view = CampaignRouter.getCampaignView(campaignId, name);
      const isCurrent = CampaignView.isCurrent();

      that.listenToOnce(view, 'show', function() {
        Projects.load(id).done(function(project) {
          view.getRegion('main').show(
            new MembersSuggestionsView({
              model: project,
              api,
              tmplPermissions: tmplProjectPermissions(),
              leaveRoute: ProjectRoutes.campaignProjectSettings(
                campaignId,
                name,
                id
              )
            })
          );
        });
      });

      if (!isCurrent) {
        app.m.getRegion('main').show(view);
      } else {
        view.trigger('show');
      }
    });
  },
  addCampaigns(id) {
    log.debug('addMembers');

    require.ensure([], function() {
      const CampaignsSuggestionsView = require('views/permissions/CampaignsSuggestionsView');
      const tmplPermissions = require('permissions/ProjectCampaignRolePermissions.tmpl');
      const api = require('api/CampaignPermissionsAPI');

      Projects.load(id).done(function(project) {
        app.m.getRegion('main').show(
          new CampaignsSuggestionsView({
            model: project,
            api,
            tmplPermissions: tmplPermissions(),
            leaveRoute: ProjectRoutes.projectSettings(id)
          })
        );
      });
    });
  },
  // add additional campaigns to a campaign project
  campaignAddCampaigns(campaignId, name, id) {
    const that = this;

    require.ensure([], function() {
      const CampaignView = require('views/campaigns/CampaignView');

      const CampaignsSuggestionsView = require('views/permissions/CampaignsSuggestionsView');
      const tmplPermissions = require('permissions/ProjectCampaignRolePermissions.tmpl');
      const api = require('api/CampaignPermissionsAPI');

      const CampaignRouter = require('routers/CampaignRouter');
      const view = CampaignRouter.getCampaignView(campaignId, name);
      const isCurrent = CampaignView.isCurrent();

      that.listenToOnce(view, 'show', function() {
        Projects.load(id).done(function(project) {
          view.getRegion('main').show(
            new CampaignsSuggestionsView({
              model: project,
              api,
              tmplPermissions: tmplPermissions(),
              leaveRoute: ProjectRoutes.campaignProjectSettings(
                campaignId,
                name,
                id
              )
            })
          );
        });
      });

      if (!isCurrent) {
        app.m.getRegion('main').show(view);
      } else {
        view.trigger('show');
      }
    });
  },
  addOrgs(id) {
    require.ensure([], function() {
      const OrgsSuggestionsView = require('views/permissions/OrgsSuggestionsView');
      const tmplPermissions = require('permissions/ProjectOrgRolePermissions.tmpl');
      const api = require('api/OrgPermissionsAPI');

      Projects.load(id).done(function(project) {
        app.m.getRegion('main').show(
          new OrgsSuggestionsView({
            model: project,
            api,
            tmplPermissions: tmplPermissions(),
            leaveRoute: ProjectRoutes.projectSettings(id)
          })
        );
      });
    });
  },
  // add additional orgs to a campaign project
  campaignAddOrgs(campaignId, name, id) {
    const that = this;

    require.ensure([], function() {
      const CampaignView = require('views/campaigns/CampaignView');

      const OrgsSuggestionsView = require('views/permissions/OrgsSuggestionsView');
      const tmplPermissions = require('permissions/ProjectOrgRolePermissions.tmpl');
      const api = require('api/OrgPermissionsAPI');

      const CampaignRouter = require('routers/CampaignRouter');
      const view = CampaignRouter.getCampaignView(campaignId, name);
      const isCurrent = CampaignView.isCurrent();

      that.listenToOnce(view, 'show', function() {
        Projects.load(id).done(function(project) {
          view.getRegion('main').show(
            new OrgsSuggestionsView({
              model: project,
              api,
              tmplPermissions: tmplPermissions(),
              leaveRoute: ProjectRoutes.campaignProjectSettings(
                campaignId,
                name,
                id
              )
            })
          );
        });
      });

      if (!isCurrent) {
        app.m.getRegion('main').show(view);
      } else {
        view.trigger('show');
      }
    });
  },
  projects(groupId) {
    log.info('ProjectRouter.projects()');

    import(
      /* webpackChunkName: "UserProjectsView" */
      'views/projects/UserProjectsView'
    ).then(({ default: UserProjectsView }) => {
      if (
        $('#projectsViewContainer').length &&
        $('.campaign-view #projectsViewContainer').length === 0 &&
        $('.org-view #projectsViewContainer').length === 0
      ) {
        log.info('ProjectsView server-side, attachView');
        app.m.getRegion('main').attachView(
          new UserProjectsView({
            attached: true
          })
        );
      } else if (app.hasCache) {
        $('#main').html(app.pageCache);

        app.m.getRegion('main').attachView(
          new UserProjectsView({
            attached: true
          })
        );
      } else {
        app.m.getRegion('main').show(new UserProjectsView());
      }
    }); // import
  },
  getProjectView(region, project, selected) {
    let projectView = region.currentView;

    if (
      !(region.currentView instanceof ProjectView) ||
      projectView.model.id !== project.id
    ) {
      projectView = this.createProjectView(region, project, selected);
    }

    app.events.trigger(PageEvents.changed, selected);

    return projectView;
  },
  createProjectView(region, project, selected) {
    // log.debug('createProjectView');

    const projectView = new ProjectView({
      model: project
    });
    region.show(projectView);
    projectView.header.show(
      new ProjectTitleView({
        model: project,
        selected
      })
    );

    return projectView;
  }
}); // router
