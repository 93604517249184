//ProjectEvents
module.exports = {
	addedArchiveFiles: 'added-archive-files',
	updatedMapStatus: 'updated-map-status',
	showFolderTitle: 'show-folder-title',
	hideFolderTitle: 'hide-folder-title',
	showSubNav: 'show-subnav',
	hideSubNav: 'hide-subnav',
	savedName: 'saved-project-name',
	triggerFolderDownload: 'trigger-folder-download'
}