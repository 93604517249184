var SocketIOListener = require('events/SocketIOListener');

var tmplProjectView = require('projects/ProjectView.tmpl');

//ProjectView
module.exports = Backbone.Marionette.LayoutView.extend({
	template: tmplProjectView,
	regions: {
		header: '.header',
		main: '.main'
	},
	onDestroy: function() {
		app.views.projects = null;

		var channel = "project:" + this.model.id;
		SocketIOListener.leave(channel);
	},
	initialize: function() {
		var that = this;
		log.debug('ProjectView.initialize');

		var channel = "project:" + this.model.id;

		SocketIOListener.join(channel);
	}
}); //ProjectsView