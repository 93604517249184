var Campaign = require('./Campaign');
var Models = require('models/Models');

module.exports = Backbone.PageableCollection.extend({
	url: "/api/campaigns",
	model: Campaign,
	findName: function(query) {
		var models = this.filter(function(model) {
			return (model.get("name").indexOf(query) >= 0);
		});

		return new Campaigns(models);
	}},{
	/*
	* returns a Campaign from the collection or fetches it remotely
	* and sets it as app.selected.campaign
	*/
	load: function(id) {
		var that = this;
		var deferred = $.Deferred();

		that.lookup(id).done(function( campaign ) {
			app.selected.campaign = campaign;

			var campaigns = app.models.get(Models.CAMPAIGNS);
			if (!campaigns.get(id)) {
				campaigns.add(app.selected.campaign);
			}
			app.models.set(Models.CAMPAIGNS, campaigns);

			deferred.resolveWith(this, [campaign]);
		});

		return deferred.promise();
	},
	/*
	* returns a Campaign from the collection or fetches it remotely
	*/
	lookup: function(id) {
		var model;
		var deferred = $.Deferred();

		if (model = app.models.get(Models.CAMPAIGNS).get(id)) {
			deferred.resolveWith(this, [model]);
		} else {
			model = new Campaign({id: id});
			model.fetch({
				success: function(model, response, options) {
			    	deferred.resolveWith(this, [model]);
				}
			});
		}

		return deferred.promise();
	}
});