import "rw-panel.styl";
import "form/settings-form.styl";
import "feedback/feedback.styl";

const api = require("api/UserAPI");
const tmpl = require("feedback/FeedbackView.tmpl");

// FeedbackView
module.exports = Backbone.Marionette.ItemView.extend({
  template: tmpl,
  events: {
    "click .modal-bg": "closeFeedback"
  },
  ui: {
    modal: '.modal',
  },

  closeFeedback(event) {
    this.ui.modal.remove();
  }
});
