
var Project = require('models/Project');

var tmplUserRoleLevel = require('permissions/user/UserRoleLevel.tmpl');
var tmplRoleExplanation = require('permissions/user/RoleExplanation.tmpl')

//UserRoleView
module.exports = {
	setUserRoleIndicator: function(model) {
		var that = this;

		var el = this.ui.usrRoleLevel;

		var roleName = model.get('level');

		el.addClass(roleName);
		el.html(tmplUserRoleLevel());

		var desc = model.getUserRoleDescription();

		el.qtip({
			style: 'qtip-dark qtip-members-roles qtip-detailed-roles',
			content: {
			    text: function(api) {
					$('.qtip.ui-tooltip').qtip('hide');

					if (that.model instanceof Project) {
						var roles = that.getRoleJSON(that.model.id);

						var obj = {
							roles: roles,
							desc: desc.text
						};

						return tmplRoleExplanation(obj);

					} else {
						return desc.text;
					}
				},
			    title: {
			       text: desc.title
			    }
 			},
 			position:{
 				target: el.find('img.'+roleName),
				at: 'left center',
				my: 'right top',
					effect:false,
					viewport: $(window)
			}
		});
	},
	getRoleJSON: function(id) {
		var that = this;

		var info = null;

		$.ajax({
			type: "GET",
			dataType: "json",
			url: that.api.roleExplanation(id),
			async: false,
			success: function(json) {
				info = json;
			}
		});

		return info;
	}
};