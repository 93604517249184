//URL
module.exports = {
	getParams: function(){
		var vars = [], hash;
		var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
		for(var i = 0; i < hashes.length; i++) {
			hash = hashes[i].split('=');
			vars.push(hash[0]);
			vars[hash[0]] = hash[1];
		}
		return vars;
	},
	getParam: function(name){
		return this.getParams()[name];
	},
	withHTTP: function(url) {
		if (!!url && !!url.trim()) { //Check if url is not blank
		    url = url.trim(); //Removes blank spaces from start and end
		    if (!/^(https?:)?\/\//i.test(url)) { //Checks for if url doesn't match either of: http://example.com, https://example.com AND //example.com
		        url = 'http://' + url; //Prepend http:// to the URL
		    }
		}

		return url;
	}
};